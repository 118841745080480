export const mixins = {
  created() {
    if (process.server) return;
    this.useRem();
  },
  mounted() {
    let isMobi = false;
    for (
      let v = 0;
      v < ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'].length;
      v += 1
    ) {
      if (
        navigator.userAgent.indexOf(
          ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'][v],
        ) > 0
      ) {
        isMobi = true;
        break;
      }
    }
    window.addEventListener('resize', this.useRem);
    if (isMobi) {
      const wrap = document.querySelector('.page-wrap');
      if (wrap) {
        wrap.style.height = `${window.innerHeight}px`;
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.useRem);
  },
  methods: {
    useRem() {
      document.documentElement.style.fontSize = `${Math.min(window.innerWidth / 428, 1)}px`;
    },
  },
};
export default mixins;
