<template>
  <svg class="svg-end-screen" v-bind="svgAttrs">
    <image :href="img[storyId]" :width="w" :height="h" />
    <g
      v-for="(role, i) in roles"
      :key="i"
      :transform="`translate(${role.pos[0]},${role.pos[1]})`"
      v-show="role.join"
    >
      <image
        :class="`role_${i}`"
        :href="role.roleImg"
        :width="role.roleSize"
        :height="role.roleSize"
        :x="-role.roleSize / 2"
        :y="-role.roleSize / 2"
      />
      <SvgQuadBackground
        :width="role.roleSize * 1.2"
        :height="60"
        :x="-role.roleSize / 2 - 7"
        :y="role.roleSize / 2 - 30"
        :color="colorList[i]"
        direction="R"
      />
      <g :transform="`translate(${-role.roleSize / 2 + 8},${role.roleSize / 2 - 15})`">
        <text :x="80" :y="20" class="role-name" text-anchor="middle">
          {{ role.name || `匿名用戶${role.num + 1}` }}
        </text>
      </g>
    </g>
  </svg>
</template>

<script>
/* eslint-disable no-param-reassign */
/* eslint-disable global-require */
import SvgQuadBackground from './SvgQuadBackground.vue';
import SvgValueBar from './SvgValueBar.vue';

const tagsMap = {
  KONGKOO_1: {
    tags: ['扶持追夢', '努力證明', '守護老小', '讓人安心'],
  },
  KONGKOO_2: {
    tags: ['開創未來', '放手追夢', '守護過去', '安穩生活'],
  },
  KONGKOO_3: {
    tags: ['慷慨解囊', '鼓勵嘗試', '穩重避險', '冷靜評估'],
  },
};

const playerTypes = {
  INTJ: 0,
  INTP: 0,
  ENTJ: 0,
  ENTP: 0,

  INFJ: 1,
  INFP: 1,
  ENFJ: 1,
  ENFP: 1,

  ISTJ: 2,
  ISFJ: 2,
  ESTJ: 2,
  ESFJ: 2,

  ISTP: 3,
  ISFP: 3,
  ESTP: 3,
  ESFP: 3,
};

const vecList = [
  [0, -1],
  [1.2, 0],
  [0, 1],
  [-1.2, 0],
];
const colorList = ['#93C363', '#FFCC4A', '#EE8398', '#BB9AFF'];

export default {
  components: {
    SvgQuadBackground,
  },
  props: {},
  computed: {
    screenId() {
      const { screenId } = this.$store.state.kongkoo;
      return screenId;
    },
    storyId() {
      const { screenId } = this;
      if (screenId === 'screen_0') {
        return 'KONGKOO_1';
      }
      if (screenId === 'screen_1') {
        return 'KONGKOO_2';
      }
      if (screenId === 'screen_2') {
        return 'KONGKOO_3';
      }
      console.log('W:', screenId);
      return 'KONGKOO_3';
    },
    tags() {
      const { storyId } = this;
      return tagsMap[storyId];
    },
    roles() {
      const { w, h } = this;
      const roleSize = 190;
      const { tags } = tagsMap[this.storyId];
      const { players } = this.$store.state.kongkoo;
      const roleShift = [
        [-1, -1],
        [1, -1],
        [1, 1],
        [-1, 1],
      ];
      for (const p of roleShift) {
        this.posMult(p, (roleSize * 1.2) / 2);
      }
      const results = players.map(({
        name, ready, join, pathTags, mbti,
      }, i) => {
        // ['外向', '直覺', '理性', '熟思']
        let roleNum = i;
        const enableMbti = false;
        if (enableMbti) {
          const vals = [
            mbti['外向'] || 0, // E/I
            mbti['直覺'] || 0, // N/S
            mbti['理性'] || 0, // T/F
            mbti['熟思'] || 0, // P/J
          ];
          // console.log(vals);
          const map = [
            ['E', 'I'],
            ['N', 'S'],
            ['T', 'F'],
            ['P', 'J'],
          ];
          let str = '';
          for (let k = 0; k < 4; k += 1) {
            const v = vals[k];
            const keys = map[k];
            if (v > 0) {
              str += keys[0];
            } else {
              str += keys[1];
            }
          }
          console.log(str);
          if (str in playerTypes) {
            roleNum = playerTypes[str];
            console.log(playerTypes[str], this.roleImg[roleNum]);
          } else {
            roleNum = Math.floor(Math.random() * 4);
          }
        } else {
          // 若關閉 mbti 自動判斷角色
          // 則隨機選一個角色圖
          roleNum = Math.floor(Math.random() * 4);
        }
        const vecS = [0, 0];
        for (const tag of pathTags) {
          const n = tags.findIndex((t) => t === tag);
          const vec = vecList[n] || [0, 0];
          vecS[0] += vec[0];
          vecS[1] += vec[1];
        }
        console.log(vecS);
        this.posMult(vecS, 210);
        const mid = [w / 2 - 24, h / 2 + 18];
        const pos = [0, 0];
        this.posAdd(pos, vecS);
        this.posAdd(pos, mid);
        this.posAdd(pos, roleShift[i]);
        // pos[0] += i * (roleSize + midGap);
        return {
          num: i,
          name,
          ready,
          join,
          roleImg: this.roleImg[roleNum],
          roleSize,
          pos,
        };
      });
      console.log(results);
      return results;
    },
    svgAttrs() {
      const { w, h } = this;
      const obj = {
        width: w,
        height: h,
        viewBox: [0, 0, w, h].join(' '),
        // preserveAspectRatio: 'none',
        version: '1.1',
        xmlns: 'http://www.w3.org/2000/svg',
      };
      return obj;
    },
  },
  data() {
    const w = 1920;
    const h = 1080;
    return {
      w,
      h,
      colorList,
      roleImg: [
        require('@/assets/role1.png'),
        require('@/assets/role2.png'),
        require('@/assets/role3.png'),
        require('@/assets/role4.png'),
      ],
      img: {
        KONGKOO_1: require('@/assets/end-bg-1.svg'),
        KONGKOO_2: require('@/assets/end-bg-2.svg'),
        KONGKOO_3: require('@/assets/end-bg-3.svg'),
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    measureText(text, size, weight) {
      /** @type {CanvasRenderingContext2D} */
      // console.log('aaaa', this.$ctx, this.$canvas);
      const ctx = this.$ctx;
      weight = weight || 'bold';
      ctx.font = `${weight} ${size}px 'Noto Sans TC S', 'Noto Sans TC'`;
      return ctx.measureText(text);
    },
    posAdd(pos1, pos2) {
      pos1[0] += pos2[0];
      pos1[1] += pos2[1];
    },
    posMult(pos, v) {
      pos[0] *= v;
      pos[1] *= v;
    },
  },
};
</script>

<style lang="scss" scoped>
.svg-end-screen {
  width: 100vw;
  height: 100vh;
}
svg {
  font-family: 'Noto Sans TC S', 'Noto Sans TC';
  .end-title {
    letter-spacing: 18px;
    font-size: 95px;
    font-weight: bold;
    fill: #4933cd;
    stroke: #4933cd;
    filter: drop-shadow(3px 3px 0px white);
  }
  .role-name {
    font-size: 25px;
    font-weight: bold;
    fill: #fff;
  }
  .prov-title {
  }
  .product-title {
  }
}
</style>
