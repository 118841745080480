<template>
  <svg class="svg-prepare-screen" v-bind="svgAttrs">
    <!-- <SvgQuadBackground x="70" y="140" :width="800" :height="800" /> -->
    <g>
      <image x="700" y="80" :href="img.logo" />
    </g>
    <g v-if="!zoom">
      <image x="300" y="250" :href="qrcode" @click="onQRcodeClick" />
    </g>
    <!-- <svg
      class="qrcode"
      :x="qrcodeBlock.x"
      :y="qrcodeBlock.y"
      :width="qrcodeBlock.svgSize"
      :height="qrcodeBlock.svgSize"
      :viewbox="qrcodeBlock.viewbox"
      fill="none"
    >
      <image
        :x="qrcodeBlock.imgShift"
        :y="qrcodeBlock.imgShift"
        :width="qrcodeBlock.imgSize"
        :height="qrcodeBlock.imgSize"
        :href="qrcode"
      />
    </svg> -->
    <!-- <svg
      class="qrcode"
      :x="qrcodeBlock.x"
      :y="qrcodeBlock.y"
      :width="qrcodeBlock.svgSize"
      :height="qrcodeBlock.svgSize"
      :viewbox="qrcodeBlock.viewbox"
      fill="none"
    >
      <image
        :x="qrcodeBlock.imgShift"
        :y="qrcodeBlock.imgShift"
        :width="qrcodeBlock.imgSize"
        :height="qrcodeBlock.imgSize"
        :href="qrcode"
      />
    </svg> -->
    <!-- <image x="0" y="0" :href="qrcode" /> -->
    <text v-if="!zoom" class="scan-qrcode" x="300" y="850">掃描QRcode</text>
    <text v-if="!!zoom" class="scan-qrcode" x="150" y="830">roomId:</text>
    <text v-if="!!zoom" class="scan-qrcode" x="150" y="950">{{ roomId }}</text>
    <!-- <g class="enter-btn" @click="start">
      <image x="1065" y="341" :href="img.arrow" />
      <text class="enter-story" x="1275" y="448">進入故事</text>
    </g> -->
    <g class="enter-btn" @click="start">
      <image x="1145" y="680" :href="img.startImg" />
    </g>
    <text class="player-title" x="1139" y="300">當前玩家（{{ playerCount }}/4）</text>
    <g :transform="`translate(${playersBlock.x},${playersBlock.y})`">
      <template v-for="(player, i) in players">
        <image
          :x="i * playersBlock.playerGap"
          :key="'img' + i"
          :href="player.join ? img.join : img.empty"
        />
        <text
          class="player-name"
          :x="playersBlock.textX + i * playersBlock.playerGap"
          :y="150"
          :key="'text' + i"
          text-anchor="middle"
        >
          {{ player.name }}
        </text>
      </template>
    </g>
  </svg>
</template>

<script>
/* eslint-disable no-param-reassign */
/* eslint-disable global-require */
import { /* EV, */ A, M, ns } from '@/lib/kongkooV2-def';
// import SvgQuadBackground from './SvgQuadBackground.vue';
// import SvgValueBar from './SvgValueBar.vue';

export default {
  // components: {
  //   SvgQuadBackground,
  // },
  props: {},
  computed: {
    roomId() {
      const { roomId } = this.$store.state.kongkoo;
      return roomId;
    },
    view() {
      const { view } = this.$store.state.kongkoo;
      return view;
    },
    svgAttrs() {
      const { w, h } = this;
      const obj = {
        width: w,
        height: h,
        viewBox: [0, 0, w, h].join(' '),
        // preserveAspectRatio: 'none',
        version: '1.1',
        xmlns: 'http://www.w3.org/2000/svg',
      };
      return obj;
    },
    qrcodeBlock() {
      const x = 120;
      const y = 190;
      // const svgSize = 700;
      const svgSize = 505;
      const imgShift = -svgSize / 8;
      const imgSize = svgSize - imgShift - imgShift;
      const viewBox = [0, 0, svgSize, svgSize].join(' ');
      return {
        x,
        y,
        svgSize,
        imgSize,
        viewBox,
        imgShift,
      };
    },
    playersBlock() {
      const x = 1140;
      const y = 350;
      const playerGap = 140;
      const textX = 44;
      return {
        textX,
        x,
        y,
        playerGap,
      };
    },
    players() {
      const { players } = this.$store.state.kongkoo;
      return players;
    },
    playerCount() {
      const { players } = this;
      let count = 0;
      for (const state of players) {
        if (state.sid && state.email) {
          count += 1;
        }
      }
      return count;
    },
    clientLink() {
      const { roomId } = this;
      const { protocol, host } = window.location;

      const link = `${protocol}//${host}/#/kongkoo/user/signIn?roomId=${roomId}`;
      console.log('client link:', link);
      return link;
    },
    qrcode() {
      // return require('@/assets/SvgPrepareScreen/QRCode.svg');
      const { roomId } = this;
      const size = 500;
      if (roomId) {
        const url = encodeURIComponent(this.clientLink);
        console.log(`https://chart.googleapis.com/chart?cht=qr&chl=${url}&chs=${size}x${size}`);
        return `https://chart.googleapis.com/chart?cht=qr&chl=${url}&chs=${size}x${size}`;
      }
      return '';
    },
  },
  data() {
    const w = 1920;
    const h = 1080;
    const { zoom } = this.$route.query;
    return {
      w,
      h,
      zoom,
      waitChangePage: false,
      img: {
        QRCode: require('@/assets/SvgPrepareScreen/QRCode.svg'),
        logo: require('@/assets/SvgPrepareScreen/logo.svg'),
        startImg: require('@/assets/SvgPrepareScreen/start-btn.svg'),
        arrow: require('@/assets/parepare-arrow.svg'),
        empty: require('@/assets/player-empty.svg'),
        join: require('@/assets/player-join.svg'),
      },
    };
  },
  created() {},
  mounted() {
    console.log(this.zoom, '???zoom');
  },
  methods: {
    measureText(text, size, weight) {
      /** @type {CanvasRenderingContext2D} */
      // console.log('aaaa', this.$ctx, this.$canvas);
      const ctx = this.$ctx;
      weight = weight || 'bold';
      ctx.font = `${weight} ${size}px 'Noto Sans TC S', 'Noto Sans TC'`;
      return ctx.measureText(text);
    },
    onQRcodeClick() {
      const { clientLink } = this;
      const a = document.createElement('a');
      a.href = clientLink;
      a.target = '_blank';
      a.click();
    },
    start() {
      if (this.waitChangePage) {
        return;
      }
      if (this.playerCount === 0) {
        return;
      }
      if (this.view === 'prepare') {
        this.$emit('playBgm');
        this.waitChangePage = true;
        this.$store.commit(ns('kongkoo', M.TO), 'landing');
        setTimeout(() => {
          this.$store.dispatch(ns('kongkoo', A.startGame));
        }, 4000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.svg-prepare-screen {
  position: absolute;
  z-index: 2;
  height: 100vh;
  width: 100vw;
  background-color: #ffd177;
  background-image: url('../../assets/SvgPrepareScreen/bg.png');
  // background-image: url(require('@/assets/SvgPrepareScreen/bg.png'));
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
svg {
  user-select: none;
  font-family: 'Noto Sans TC S', 'Noto Sans TC';
  .scan-qrcode {
    letter-spacing: 10px;
    font-size: 75px;
    font-weight: bold;
    fill: #5b4035;
    stroke: #5b4035;
    // filter: drop-shadow(3px 3px 0px white);
  }
  .enter-story {
    letter-spacing: 18px;
    font-size: 95px;
    font-weight: bold;
    fill: #e94747;
    stroke: #e94747;
    // filter: drop-shadow(3px 3px 0px white);
  }
  .player-title {
    // filter: drop-shadow(3px 3px 0px white);
    letter-spacing: 10px;
    font-size: 65px;
    font-weight: bold;
    fill: #5b4035;
    stroke: #5b4035;
  }
  .player-name {
    font-size: 30px;
    fill: #5b4035;
    stroke: #5b4035;
  }
  .enter-btn:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}
</style>
