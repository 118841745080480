<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-text-field
          label="roomId"
          v-model="roomId"
          @keyup.enter="changeRoomId(roomId)"
        ></v-text-field>
        <v-text-field v-model="quesId" @keyup.enter="changeQuesId"></v-text-field>
        <v-btn @click="gameStart">start</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { wsAttrs } from '@/mixins/kongkoo-ws';
import { EV, A, ns } from '@/lib/kongkooV2-def';

export default {
  layout: 'app',
  mixins: [wsAttrs],
  data() {
    return {
      roomId: '',
      quesId: 'S01',
    };
  },
  mounted() {
    this.roomId = this.$route.query.roomId;
    if (!this.roomId) {
      this.changeRoomId('default');
      return;
    }
    this.init();
  },
  methods: {
    empty() {},
    async init() {
      const opts = {
        type: 'screen',
        roomId: this.roomId,
      };
      console.log('roomId', this.roomId);
      const socket = await this.$store.dispatch(ns('kongkoo', A.initConnection), opts);
      console.log('[server connected]', opts.type);
      window.socket = socket;
      socket.on(EV.user.update_state, (uState) => {
        console.log('user state:', uState);
      });
    },
    changeRoomId(roomId) {
      this.roomId = roomId;
      this.$router.replace(`/kongkoo/controler?roomId=${roomId}`);
    },
    changeQuesId() {
      const { roomId, quesId, socket } = this;
      socket.emit(EV.control.change_ques, { roomId, quesId });
    },
    gameStart() {
      const { roomId, quesId, socket } = this;
      socket.emit(EV.control.change_ques, { roomId, quesId });
      socket.emit(EV.control.start);
    },
  },
};
</script>

<style lang="scss" scoped></style>
