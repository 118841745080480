/* eslint-disable guard-for-in */

/*
  定義各種常數
*/

export const M = {
  SETUP_CONNECTION: '',
  SET_ID: '',
  SET_NAME: '',
  SET_TYPE: '',
  PLAYERS_RESET: '',
  PLAYER_RESET_MBTI: '',
  PLAYER_JOIN: '',
  PLAYER_RESET: '',
  PLAYER_SET_NAME: '',

  SET_STORY_ID: '',
  SET_SCREEN_ID: '',

  SET_USER_MSG: '',
  TO: '',
};
// 自動填入
for (const key in M) {
  M[key] = key;
}

export const A = {
  initConnection: '',
  initDisplayerEvents: '',
  initAdminEvents: '',
  initUserEvents: '',
  resetPlayers: '',
  requestPromotion: '',
  setNickname: '',
  joinRoom: '',
  startGame: '',
  endGame: '',
};
// 自動填入
for (const key in A) {
  A[key] = key;
}

export const EV = {
  room: {
    leave: '',
    join: '',
    user_leave: '',
    user_join: '',
    reject: '', // 被拒絕進入房間
    kick: '',
    ping_screen: '',
    pong_screen: '',
  },
  user: {
    set_name: '',
    update_name: '',
    promotion: '',
  },
  game: {
    start: '',
    start_trigger: '',
    user_start: '',
  },
  conv: {
    end: '',
    mbti: '',
    mbti_reset: '',
    path_tag: '',
    path_tag_reset: '',
  },
};
// 自動填入
for (const groupKey in EV) {
  const g = EV[groupKey];
  if (typeof g === 'object') {
    for (const key in g) {
      // console.log(`${groupKey}:${key}`);
      g[key] = `${groupKey}:${key}`;
    }
  } else {
    M[groupKey] = groupKey;
  }
}

export function ns(namespace, func) {
  return `${namespace}/${func}`;
}
