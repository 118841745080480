<template>
  <div class="page-wrap">
    <div class="main">
      <div class="title-sec">
        <img class="bg-img" src="@/assets/user/prepare/story-title-bg.png" alt="" />
        <p class="title-text" v-html="quesTitle"></p>
      </div>
      <div class="desc-sec">{{ desc }}</div>
      <div class="wait-text">等待中...</div>
    </div>
  </div>
</template>

<script>
import { EV, A, ns } from '@/lib/kongkooV2-def';
import { wsAttrs, requireConnection } from '@/mixins/kongkoo-ws';
import remMixin from '@/mixins/useRem';

export default {
  layout: 'empty',
  mixins: [remMixin, wsAttrs, requireConnection],
  computed: {
    roomId() {
      return this.$store.state.kongkoo.roomId;
    },
    quesTitle() {
      const { ques, previewPage } = this;
      if (ques) {
        return `${ques.title || ''}<br>${ques.slogan || ''}`;
      }
      return previewPage ? '李臨秋載動的詩詞世界' : '選擇中...';
    },
    desc() {
      const { ques, defaultDesc } = this;
      if (ques) {
        return ques.provDesc || defaultDesc;
      }
      return defaultDesc;
    },
  },
  data() {
    return {
      // storyTitle: '李臨秋載動的詩詞世界',
      defaultDesc:
        '無論身在哪個年代，都會碰到要以大局為重還是堅持創作在相互拉扯，而現在的你有什麼兩全其美的解決方法嗎？',
    };
  },
  mounted() {
    const { socket } = this;
    if (socket) {
      socket.on(EV.game.start_ques, this.onStartEvent);
      socket.on(EV.user.join_reject, this.onJoinReject);
    }
  },
  beforeDestroy() {
    const { socket } = this;
    if (socket) {
      socket.off(EV.game.start_ques, this.onStartEvent);
      socket.off(EV.user.join_reject, this.onJoinReject);
    }
  },
  methods: {
    onJoinReject() {
      setTimeout(() => {
        this.$router.replace({
          path: '/kongkoo/user/signIn',
          query: {
            roomId: this.roomId,
          },
        });
      }, 3000);
    },
    onStartEvent() {
      this.$router.replace({
        path: '/kongkoo/user/play',
        query: {
          roomId: this.roomId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrap {
  width: 100vw;
  height: 100vh;
  .main {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('../../../assets/user/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 428rem;
    height: 100%;
    .title-sec {
      width: 90%;
      margin-top: 20vh;
      display: flex;
      align-items: center;
      justify-content: center;
      .bg-img {
        width: 100%;
        height: auto;
      }
      .title-text {
        position: absolute;
        font-family: 'Noto Sans TC B';
        font-style: normal;
        font-weight: 900;
        font-size: 27rem;
        letter-spacing: 0.2em;
        color: #ffffff;
        text-align: center;
      }
    }
    .desc-sec {
      width: 90%;
      margin-top: 30rem;
      margin-bottom: 70rem;
      padding: 18rem;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.6) 0%,
        rgba(255, 255, 255, 0.8) 100%
      );
      box-shadow: 0rem 4rem 4rem rgba(0, 0, 0, 0.25);
      border-radius: 10rem;
      font-family: 'Noto Sans TC S';
      font-style: normal;
      font-weight: 400;
      font-size: 16rem;
      line-height: 2;
      color: #000000;
    }
    .wait-text {
      font-family: 'Noto Sans TC S';
      font-style: normal;
      font-weight: 900;
      font-size: 24rem;
      line-height: 28rem;
      text-align: center;
      letter-spacing: 0.2em;
      color: #000000;
    }
  }
}
</style>
