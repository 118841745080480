<template>
  <section class="page-wrap">
    <div class="main">
      <CustomDialog :msg="dialogMsg" :show.sync="showDialog" />
      <img :src="logoImg" alt="" class="logo" />
      <section class="input-sec">
        <div v-if="!ready">連線中...</div>
        <p class="label">用戶暱稱</p>
        <input
          placeholder="輸入暱稱"
          class="input"
          type="text"
          @keyup.enter="submit"
          v-model="username"
          @input="(e) => onInput(e, 'username')"
          :disabled="!ready"
        />
        <br />
        <p style="margin-top: 10px" class="label">用戶Email</p>
        <input
          placeholder="Email"
          class="input"
          type="text"
          @keyup.enter="submit"
          v-model="email"
          @input="(e) => onInput(e, 'email')"
          :disabled="!ready"
        />
      </section>
      <img :src="btnImg" alt="" class="enter-btn" @click="submit()" />
    </div>
  </section>
</template>

<script>
/* eslint-disable global-require */
import { EV, A, ns } from '@/lib/kongkooV2-def';
import { wsAttrs } from '@/mixins/kongkoo-ws';
import remMixin from '@/mixins/useRem';

export default {
  layout: 'empty',
  mixins: [remMixin, wsAttrs],
  computed: {
    // user, socket, socketId 由 mixin 提供
    error() {
      return this.user.error;
    },
    message() {
      return this.user.message;
    },
  },
  data() {
    return {
      logoImg: require('@/assets/user/signIn/logo.png'),
      btnImg: require('@/assets/user/signIn/enter-game.png'),
      roomId: '',
      username: '',
      email: '',
      ready: false,
      loading: false,
      dialogMsg: '',
      showDialog: false,
    };
  },
  created() {
    window.document.title = '進入遊戲';
  },
  mounted() {
    // 如果進來這一頁的時候已經有連線
    // 通常是因為是從後面按上一頁導致
    // 這邊直接讓 user 重整確保有退出
    if (this.socket) {
      window.location.reload();
      return;
    }
    console.log('signIn', this.$route);
    const { roomId } = this.$route.query;
    this.roomId = roomId || this.loadRoomId() || 'default';

    // 在 query 沒有正確指定 roomId 的情況下
    // 用底下這段確保網址跟 roomId 是同步的
    if (this.roomId !== roomId) {
      this.$router.replace({
        path: '/kongkoo/user/signIn',
        query: {
          roomId: this.roomId,
        },
      });
    }

    this.saveRoomId(roomId);
    this.init(this.roomId);
    this.loadLoginInfo();
  },
  methods: {
    saveRoomId(roomId) {
      localStorage.setItem('kg-last-roomId', roomId);
      return roomId;
    },
    loadRoomId() {
      return localStorage.getItem('kg-last-roomId');
    },
    loadLoginInfo() {
      try {
        const json = localStorage.getItem('sign-email');
        if (json) {
          const data = JSON.parse(json);
          this.username = data.nickname;
          this.email = data.email;
        }
      } catch (e) {
        console.error(e);
      }
    },
    seveLoginInfo(data) {
      const { nickname, email } = data;
      localStorage.setItem(
        'sign-email',
        JSON.stringify({
          nickname,
          email,
        }),
      );
    },
    async init(roomId) {
      const opts = {
        type: 'user',
        roomId,
      };
      const socket = await this.$store.dispatch(ns('kongkoo', A.initConnection), opts);
      this.ready = true;
      console.log('[server connected]', opts.type);
    },
    onInput(e, key) {
      if (key !== 'username' || key !== 'email') return;
      this[key] = e.target.value;
    },
    toNextPage() {
      this.$router.push({
        path: '/kongkoo/user/prepare',
        query: {
          roomId: this.roomId,
        },
      });
    },
    submit() {
      const { socketId, username, email } = this;
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.$store
        .dispatch(ns('kongkoo', A.emailLogin), {
          sid: socketId,
          nickname: username,
          email,
        })
        .then(() => {
          this.seveLoginInfo({
            nickname: username,
            email,
          });
          this.loading = false;
          this.toNextPage();
        })
        .catch((e) => {
          let msg = '登入失敗請稍後再試';
          if (e.response && e.response.data.message) {
            msg = e.response.data.message;
          }
          console.error(e);
          this.loading = false;

          // window.alert(msg);
          this.dialogMsg = msg;
          this.showDialog = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrap {
  width: 100vw;
  height: 100vh;
  .main {
    position: relative;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('../../../assets/user/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 428rem;
    height: 100%;
    .logo {
      margin: 6vh;
      width: 244rem;
      height: auto;
    }
    .input-sec {
      margin: 3vh auto 4vh;
      flex: 1;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.6) 0%,
        rgba(255, 255, 255, 0.8) 100%
      );
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
      padding: 36rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      .label {
        font-family: 'Noto Sans TC S';
        font-style: normal;
        font-weight: 900;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        letter-spacing: 0.2em;
        color: #000000;
        margin-bottom: 18rem;
      }
      .input {
        font-family: 'Noto Sans TC S', 'Noto Sans TC';
        display: block;
        width: 100%;
        height: 58rem;
        font-size: 20rem;
        background: #ffffff;
        border: 2rem solid #d5d5d5;
        box-sizing: border-box;
        border-radius: 5rem;
        text-indent: 10rem;
        outline: 0;
        color: #818181;
        &:disabled {
          opacity: 0.8;
        }
      }
    }
    .enter-btn {
      cursor: pointer;
      width: 142.97rem;
      height: auto;
      margin-bottom: 3vh;
    }
  }
}
</style>
