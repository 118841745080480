<template>
  <div></div>
</template>
<script>
import suuid from 'short-uuid';
/*
  進到這裡之後會自動跳轉至 displayer
*/
export default {
  created() {
    // eslint-disable-next-line prefer-const
    let { quesId, ques, zoom } = this.$route.query;
    const roomId = suuid.generate();
    console.log('new-room', {
      ques,
      roomId,
      quesId,
    });
    quesId = quesId || ques || 'S01';
    this.$router.push({
      path: '/kongkoo/displayer',
      query: {
        quesId,
        roomId,
        zoom,
      },
    });
  },
  beforeDestroy() {
    // console.clear();
    console.log('clear flash page msg');
  },
};
</script>
