<template>
  <g class="svg-value-bar">
    <rect
      class="bar-shadow"
      :width="w"
      :height="h"
      :x="x"
      :y="y"
      :rx="halfHeight"
      :ry="halfHeight"
      :stroke="strokeColor"
      :stroke-width="strokeWidth"
      :style="rectStyle"
      fill="white"
    />
    <svg
      :x="valueX - maskShift"
      :y="y + 1"
      :width="valueWidth - h / 2 + valueWidthFit"
      :height="h - 1"
    >
      <rect
        class="bar-value"
        :x="maskShift"
        y="0"
        :width="valueWidth"
        height="100%"
        :rx="halfHeight"
        :ry="halfHeight"
        :fill="color"
      />
    </svg>
  </g>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 30,
    },
    x: {
      type: Number,
      default: 0,
    },
    y: {
      type: Number,
      default: 0,
    },
    value: {
      type: Number,
      default: 40,
    },
    color: {
      type: String,
      default: '#6BB8FF',
    },
    strokeColor: {
      type: String,
      default: '#580800',
    },
    direction: {
      type: String,
      default: 'L',
    },
  },
  computed: {
    w() {
      return this.width - this.strokeWidth * 2;
    },
    h() {
      const { height, strokeWidth, shadowWidth } = this;
      return height - strokeWidth * 2 - shadowWidth;
    },
    halfHeight() {
      return this.height / 2;
    },
    viewbox() {
      const { width, height } = this;
      return `0 0 ${width} ${height}`;
    },
    rectStyle() {
      const { shadowWidth, strokeColor } = this;
      const obj = {
        filter: `drop-shadow(${shadowWidth}px ${shadowWidth}px 0 ${strokeColor})`,
      };
      return obj;
    },
    valueX() {
      const {
        direction, w, valueWidth, x,
      } = this;
      // 從左往右長
      if (direction === 'L') {
        return x + 1;
      }
      if (direction === 'R') {
        return x + w - valueWidth - 1;
      }
      return x;
    },
    valueWidth() {
      const { w, value } = this;

      let v = (w * this.parseBarValue(value)) / 100;
      if (v > w) {
        v = w;
      }
      if (v < 0) {
        v = 0;
      }
      return v;
    },
    maskShift() {
      const {
        direction, h, w, valueWidth,
      } = this;
      if (valueWidth > w - h / 2) {
        return 0;
      }
      if (direction === 'L') {
        return h / 2;
      }
      if (direction === 'R') {
        return -h / 2;
      }
      return 0;
    },
    valueWidthFit() {
      const {
        direction, h, w, valueWidth,
      } = this;
      if (valueWidth > w - h / 2) {
        return h / 2;
      }
      return 0;
    },
  },
  data() {
    return {
      shadowWidth: 2,
      strokeWidth: 3,
    };
  },
  methods: {
    parseBarValue(val) {
      if (val === 0) {
        return 0;
      }
      if (val > 80) {
        return 100;
      }
      if (val < 20) {
        return 20;
      }
      return val;
    },
  },
};
</script>

<style lang="scss" scoped>
.bar-shadow {
}
</style>
