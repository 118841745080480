<template>
  <div class="page-wrap">
    <img src="@/assets/prev-btn.png" class="prev-btn" @click="goToStoryList"/>
    <img
      v-show="isAudioPause === false"
      class="audio-icon"
      src="@/assets/music.png"
      alt=""
      @click.prevent.stop="pauseBgm"
    />
    <img
      v-show="isAudioPause === true"
      class="audio-icon"
      src="@/assets/no-music.png"
      alt=""
      @click.prevent.stop="playBgm"
    />
    <div slot="button-prev" class="swiper-button-prev" @click="prevBtnClick">
      <img src="@/assets/swiperPage/l-arrow.png" />
    </div>
    <div slot="button-next" class="swiper-button-next" @click="nextBtnClick">
      <img src="@/assets/swiperPage/r-arrow.png" />
    </div>
    <img class="title-img" src="@/assets/swiperPage/title-img.png" alt="" />
    <!-- <div class="debug-box"> -->
    <div ref="main" class="main">
      <section v-for="(player, i) in players" :style="cardsStyle[i]" :key="i" class="card">
        <template v-if="player.panelData">
          <div class="name-box">
            <span class="text">{{ player.nickname }}</span>
          </div>
          <TurnAblePanel ref="panel" :panelData="player.panelData" />
          <img
            @click="handleTurn(i)"
            :class="{ hide: !cardsStyle[i] || cardsStyle[i].opacity < 0.95 }"
            class="turn-page-text"
            src="@/assets/swiperPage/turn-page-text.png"
            alt=""
          />
        </template>
      </section>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import TurnAblePanel from '@/components/TurnAblePanel.vue';

// const panelSrc = require('@/assets/swiperPage/fake-panel-card.png');

export default {
  components: { TurnAblePanel },
  computed: {
    players() {
      // return [
      //   {
      //     panelData: {
      //       back: {
      //         text: 'yooosadlksandksandkaldnaklsdnadklnadk',
      //       },
      //     },
      //   },
      //   {
      //     panelData: {
      //       back: {
      //         text: 'yooosadlksandksandkaldnaklsdnadklnadk',
      //       },
      //     },
      //   },
      //   {
      //     panelData: {
      //       back: {
      //         text: 'yooosadlksandksandkaldnaklsdnadklnadk',
      //       },
      //     },
      //   },
      // ];
      return this.$store.state.kongkoo.players;
    },
  },
  data() {
    return {
      isAudioPause: null,
      cardsStyle: [],
      activeCardI: null,
      scrollX: null,
      eventState: {},
      moveEventActive: false,
      // items: [
      //   {
      //     name: 'User1',
      //     panelSrc,
      //   },
      //   {
      //     name: 'User1',
      //     panelSrc,
      //   },
      //   {
      //     name: 'User1',
      //     panelSrc,
      //   },
      //   {
      //     name: 'User1',
      //     panelSrc,
      //   },
      // ],
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 130,
        centeredSlides: true,
      },
    };
  },
  watch: {
    async scrollX(scrollX) {
      let x = scrollX;
      const allCard = document.querySelectorAll('.card');
      // const wrapW = this.$refs.main.clientWidth;
      // const wrapCenterX = wrapW / 2;
      const cardW = allCard[0].clientWidth; // 每個卡片的寬度都一樣
      if (x < 0 && Math.abs(x) > cardW * 2) {
        x = -cardW * 2;
        this.scrollX = x;
      }
      if (x > 0 && x > this.$refs.main.clientWidth - window.innerWidth / 2 - cardW) {
        x = this.$refs.main.clientWidth - window.innerWidth / 2 - cardW;
        this.scrollX = x;
      }
      this.$refs.main.style.transform = `translateX(${-x}px)`;
      setTimeout(() => {
        const cardsStyle = [];
        const allCards = document.querySelectorAll('.card');
        [].forEach.call(allCards, (item, i) => {
          const dX = window.innerWidth / 2 - item.getBoundingClientRect().x;
          const scale = Math.max(Math.min(cardW / dX, 1), 0.8);
          if (scale === 1) {
            this.activeCardI = i;
          }
          const opacity = scale;
          cardsStyle[i] = {
            transform: `scale(${scale})`,
            opacity,
          };
        });
        this.cardsStyle = cardsStyle;
      }, 300);
    },
  },
  created() {
    if (process.server) return;
    this.useRem();
    const time = window.bigScreenStartBgm.currentTime;
    setTimeout(() => {
      this.isAudioPause = window.bigScreenStartBgm.currentTime === time;
    }, 100);
  },
  mounted() {
    const allCard = document.querySelectorAll('.card');
    const cardsStyle = [];
    [].forEach.call(allCard, (item, i) => {
      const val = i === 1 ? '1' : '0.8';
      cardsStyle[i] = {
        transform: `scale(${val})`,
        opacity: `${val}`,
      };
    });
    this.cardsStyle = cardsStyle;
    window.addEventListener('resize', this.useRem);
    if (this.isMobi) {
      document.querySelector('.page-wrap').style.height = `${window.innerHeight}px`;
      window.addEventListener('touchstart', (e) => this.handleSwipeStart());
      window.addEventListener('touchmove', (e) => this.handleSwipeMove(e, 'touch'));
      window.addEventListener('touchend', (e) => this.handleSwipeEnd());
    } else {
      window.addEventListener('mousedown', this.handleSwipeStart);
      window.addEventListener('mousemove', this.handleSwipeMove);
      window.addEventListener('mouseup', this.handleSwipeEnd);
    }
    const cardW = allCard[0]?.clientWidth;
    if (cardW) {
      this.scrollX = -cardW;
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.useRem);
  },
  methods: {
    goToStoryList() {
      window.location.replace('https://kongkoo.co/storySwiper');
    },
    pauseBgm() {
      if (this.isAudioPause === true) return;
      window.bigScreenStartBgm.pause();
      this.isAudioPause = true;
    },
    playBgm() {
      if (this.isAudioPause === false) return;
      try {
        window.bigScreenStartBgm.play();
        this.isAudioPause = false;
      } catch (error) {
        console.log(error, 'playBgm err');
      }
    },
    prevBtnClick() {
      const len = document.querySelectorAll('.card')?.length || 0;
      this.addTransition();
      this.scrollX -= this.$refs.main.clientWidth / len;
    },
    nextBtnClick() {
      const len = document.querySelectorAll('.card')?.length || 0;
      this.addTransition();
      this.scrollX += this.$refs.main.clientWidth / len;
    },
    handleTurn(i) {
      if (this.cardsStyle[i].opacity < 0.95) return;
      this.$refs.panel[i].toggleTurn();
    },
    addTransition() {
      const elem = this.$refs.main;
      if (elem) {
        elem.style.transition = '0.3s';
        setTimeout(() => {
          elem.style.transition = '0s';
        }, 350);
      }
    },
    addPrettyTransition(speed) {
      if (Math.abs(speed) < 3 || !Number.isFinite(speed)) {
        this.eventState.moveSpeed = 0;
      } else {
        const slideSpeed = speed / 1.05;
        this.eventState.moveSpeed = slideSpeed;
        this.scrollX += slideSpeed;
        setTimeout(() => {
          this.addPrettyTransition(slideSpeed);
        }, 13);
      }
    },
    useRem() {
      const maxSize = window.screen.width > 1440 ? 1 : 0.86;
      // document.documentElement.style.fontSize = `${Math.min(window.innerWidth / 1600, 1)}px`;
      document.documentElement.style.fontSize = `${Math.min(window.innerWidth / 428, maxSize)}px`;
    },
    handleClick(i, key = 'KG_0_bh5KErZrnfpXRfkQL5czCX') {
      window.open(`https://ws.voiss.cc/#/kongkoo/displayer?key=${key}`);
    },
    handleSwipeStart() {
      this.moveEventActive = true;
    },
    handleSwipeMove(e, action = 'click') {
      if (!this.moveEventActive || this.underPageReady) return;
      let clientX;
      if (action === 'touch') {
        clientX = e.touches?.[0]?.clientX;
      } else if (action === 'click') {
        clientX = e.clientX;
      }
      const prevClientX = this.eventState.clientX;
      if (!prevClientX) {
        this.eventState.clientX = clientX;
        return;
      }
      const dX = prevClientX - clientX;
      this.eventState.moveSpeed = dX;
      this.scrollX += dX;
      this.eventState.clientX = clientX;
    },
    handleSwipeEnd() {
      this.addPrettyTransition(this.eventState.moveSpeed);
      this.moveEventActive = false;
      this.eventState.clientX = null;
    },
    eventRemove() {
      if (this.isMobi) {
        window.removeEventListener('touchstart', this.handleSwipeStart);
        window.removeEventListener('touchmove', this.handleSwipeMove);
        window.removeEventListener('touchend', this.handleSwipeEnd);
      } else {
        window.removeEventListener('mousedown', this.handleSwipeStart);
        window.removeEventListener('mousemove', this.handleSwipeMove);
        window.removeEventListener('mouseup', this.handleSwipeEnd);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.prev-btn {
  z-index: 5;
  cursor: pointer;
  width: 70px;
  position: absolute;
  left: 24px;
  top: 24px;
}
.audio-icon {
  z-index: 5;
  cursor: pointer;
  width: 70px;
  position: absolute;
  right: 24px;
  top: 24px;
}
.hide {
  cursor: auto !important;
  opacity: 0;
}
.title-img {
  // width: 75%;
  // height: auto;
  width: auto;
  height: 100rem;
  display: block;
  margin: auto;
}
.turn-page-text {
  margin-top: 10rem;
  cursor: pointer;
  height: 20px;
  width: auto;
}
* {
  user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
}
.page-wrap {
  // padding-top: 20px;
  min-height: 100vh;
  background-image: radial-gradient(
    49.98% 49.97% at 49.99% 50%,
    #e8e3dc 0%,
    #e9d7c1 77.76%,
    #dec4a4 100%
  );
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  // .debug-box{
  //   transform: scale(0.75) translateY(-130rem);
  // }
  .main {
    display: flex;
  }
  .card {
    perspective: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 20rem;
    transition: 0.3s;
    .name-box {
      display: flex;
      align-items: center;
      width: calc(268.5rem * 0.9);
      height: calc(62.19rem * 0.9);
      background-image: url('../../assets/swiperPage/name-bg.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      font-family: 'Noto Sans TC S';
      font-style: normal;
      font-weight: 700;
      font-size: 28rem;
      color: #580800;
      padding-left: 75rem;
      .text {
        position: relative;
        top: -4rem;
      }
    }
    // .turn-sec {
    //   transform-style: preserve-3d;
    //   transform: rotateY(0deg);
    //   transition: 0.3s;
    //   &.turn {
    //     transform: rotateY(-180deg);
    //   }
    //   .aContent {
    //     transform: translateZ(1px);
    //   }
    //   .bContent {
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     transform: rotateY(-180deg);
    //   }
    // }
    // .panel-img {
    //   width: 330rem;
    //   height: auto;
    // }
  }
}
.swiper-button-prev,
.swiper-button-next {
  cursor: pointer;
  height: auto;
  position: absolute;
  z-index: 3;
  top: 50%;
  transform: translateY(-50%);
  img {
    width: 60rem;
  }
}
.swiper-button-prev {
  left: 1%;
}
.swiper-button-next {
  right: 1%;
}
</style>
