<template>
  <div class="wrap">
    <v-text-field v-model="input" label="請輸入roomId" hide-details="auto"></v-text-field>
    <br />
    <v-btn @click="submit">送出</v-btn>
  </div>
</template>

<script>
export default {
  data() {
    return {
      input: '',
    };
  },
  methods: {
    submit() {
      this.$router.push({
        path: '/kongkoo/user/signIn',
        query: {
          roomId: this.input,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  padding: 30px;
}
</style>
