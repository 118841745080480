<template>
  <div class="page-wrap">
    <div class="main">
      <img src="@/assets/result/bg.png" alt="" class="bg" />
      <div class="text">
        透過玩讀在地文化歷史，<br />
        我們更認識了自己，<br />
        望穿老街古往今來，<br />
        你的觀點已成功被記錄下來！
      </div>
      <img @click="goKongKooPage" class="btn" src="@/assets/result/btn.png" alt="" />
    </div>
  </div>
</template>

<script>
import { wsAttrs, requireConnection } from '@/mixins/kongkoo-ws';
import remMixin from '@/mixins/useRem';

export default {
  layout: 'empty',
  mixins: [remMixin, wsAttrs, requireConnection],
  methods: {
    goKongKooPage() {
      window.open('https://kongkoo.co');
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrap {
  width: 100vw;
  height: 100vh;
  .main {
    position: relative;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 428rem;
    height: 100%;
    .bg {
      height: 100%;
      width: auto;
      margin: auto;
    }
    .text {
      width: 50%;
      position: absolute;
      left: 0;
      right: 0;
      top: 43.5%;
      margin: auto;
      font-family: 'Noto Sans TC B';
      font-style: normal;
      font-weight: 600;
      font-size: 18rem;
      line-height: 2;
      letter-spacing: 0.05em;
      color: #000000;
      white-space: nowrap;
    }
    .btn {
      position: absolute;
      cursor: pointer;
      width: 50%;
      height: auto;
      top: 80%;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}
</style>
