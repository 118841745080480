<template>
  <g :transform="transform">
    <SvgQuadBackground :x="0" :y="0" :width="width" :height="height" :color="color" />
    <text class="tag-title" text-anchor="middle" :x="width / 2" :y="height / 2 + 10">
      {{ msg }}
    </text>
  </g>
</template>

<script>
/* eslint-disable no-param-reassign */
/* eslint-disable global-require */
import SvgQuadBackground from './SvgQuadBackground.vue';

export default {
  components: {
    SvgQuadBackground,
  },
  props: {
    width: {
      type: Number,
      default: 242,
    },
    height: {
      type: Number,
      default: 77,
    },
    x: {
      type: Number,
      default: 0,
    },
    y: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      defualt: '#FF9CD2',
    },
    msg: {
      type: String,
      defualt: '#FF9CD2',
    },
  },
  computed: {
    transform() {
      const { x, y } = this;
      return `translate(${x},${y})`;
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  font-family: 'Noto Sans TC S', 'Noto Sans TC';
  .tag-title {
    font-size: 40px;
    font-weight: bold;
    fill: #fff;
    stroke: none;
  }
}
</style>
