<template>
  <div class="page-wrap" @click="playBgm">
    <div class="main">
      <CustomDialog :msg="dialogMsg" :show.sync="showDialog" />
      <div ref="moveWrap" class="moveWrap">
        <audio
          loop
          style="display: none"
          ref="audioElem"
          controls
          id="hide-audio"
          :src="ques ? ques.audio : null"
        ></audio>
        <img v-if="showSttPage" class="lantern-img" src="@/assets/user/play/lantern.png" alt="" />
        <section v-if="!showSttPage" class="opening-sec" v-html="opening"></section>
        <section v-if="!showSttPage" class="desc-sec" v-html="desc"></section>
        <section class="question-sec" v-html="question"></section>
        <section class="stt-sec" v-if="showSttPage">
          <p class="stt-title">我的跨世代回答：</p>
          <div
            @focus="focusEv"
            @blur="blurEv"
            id="stt-box"
            class="text-box"
            contenteditable="true"
            @input="inputStt"
          ></div>
        </section>
        <div class="btn-wrap">
          <img
            @click="stopRecording"
            class="record-btn"
            v-if="isRecording && !showSttPage"
            src="@/assets/user/play/stop.png"
            alt=""
          />
          <img
            @click="startRecording"
            class="record-btn"
            v-if="!isRecording && !showSttPage"
            src="@/assets/user/play/mic.png"
            alt=""
          />
          <img
            v-if="!isRecording && !showSttPage"
            class="pen-btn"
            @click="clickPen"
            src="@/assets/user/play/pen.png"
            alt=""
          />
        </div>
        <div
          v-if="showSttPage"
          class="submit-btn"
          :class="{ 'disabled-btn': loading }"
          @click="submitAnswer()"
        >
          確認送出
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import debounce from 'lodash/debounce';
import { EV /* A, ns */ } from '@/lib/kongkooV2-def';
import { stt as sttApi, ques as quesApi } from '@/lib/kongkooV2-api';
import { wsAttrs, requireConnection } from '@/mixins/kongkoo-ws';
import remMixin from '@/mixins/useRem';

export default {
  layout: 'empty',
  mixins: [remMixin, wsAttrs, requireConnection],
  data() {
    const sUserAgent = navigator.userAgent;
    let isMobi = false;
    if (
      sUserAgent.indexOf('Android') > -1
      || sUserAgent.indexOf('iPhone') > -1
      || sUserAgent.indexOf('iPad') > -1
      || sUserAgent.indexOf('iPod') > -1
      || sUserAgent.indexOf('Symbian') > -1
    ) {
      isMobi = true;
    }
    return {
      isMobi,
      showSttPage: false, // default is false
      isRecording: false,
      mediaRecorder: null,
      opening: '',
      desc: '',
      question: '',
      stt: '',
      loading: false,
      hasAudio: false,
      showDialog: false,
      dialogMsg: '',
      sttInputFocus: false,
    };
  },
  computed: {
    ...mapState('kongkoo', ['ques']),
    quesId() {
      return this.ques ? this.ques.id : '';
    },
    roomId() {
      return this.$store.state.kongkoo.roomId;
    },
    nickname() {
      return this.$store.state.kongkoo.user.nickname;
    },
    email() {
      return this.$store.state.kongkoo.user.email;
    },
  },
  watch: {
    stt(val) {
      if (this.showSttPage) return;
      if (val) {
        this.showSttPage = true;
      }
    },
    ques(ques) {
      if (ques.audio) {
        const isIOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        if (!isIOS) {
          this.playBgm();
        }
      }
      Object.assign(this, {
        opening: ques.prologue,
        desc: ques.content,
        question: ques.introduction,
      });
    },
  },
  mounted() {
    const { ques, socket } = this;
    if (!ques) {
      Object.assign(this, {
        opening:
          '補破網,<br/>是1948年(王雲峰)作曲、(李臨秋)填詞的(臺語歌曲)，也是(同名戲劇主題曲)。',
        desc: '這首歌溫柔地撫慰當時普遍低迷的民心。但由於歌詞悲苦悽情，被政府要求改換掉灰暗的情境。<br/>李臨秋不願意更動歌詞原意，只好加入第三段，(魚入港，好年冬，歌詩滿漁港)等句，營造相對歡樂的氣氛。',
        question: '如果你像李臨秋一樣，自己的作品被禁演，(你會怎麼做呢?)',
      });
    } else {
      Object.assign(this, {
        opening: ques.prologue,
        desc: ques.content,
        question: ques.introduction,
      });
    }
    if (socket) {
      socket.once(EV.game.back_home, this.backToHome);
    }
  },
  beforeDestroy() {
    const { socket } = this;
    if (socket) {
      socket.off(EV.game.back_home, this.backToHome);
    }
  },
  methods: {
    backToHome() {
      this.$router.replace({
        path: '/kongkoo/user/signIn',
        query: {
          roomId: this.roomId,
        },
      });
    },
    focusEv() {
      this.sttInputFocus = true;
      if (!this.isMobi) return;
      this.$refs.moveWrap.style = 'transform: translateY(-160px);';
    },
    blurEv() {
      this.sttInputFocus = false;
      if (!this.isMobi) return;
      this.$refs.moveWrap.style = 'transform: translateY(0px);';
    },
    clickPen() {
      this.stopBgm();
      this.showSttPage = true;
    },
    // iosPlayBgm() {
    //   if (this.hasAudio || this.showSttPage || this.isRecording) return;
    //   this.$refs.audioElem.play();
    // },
    playBgm() {
      if (this.hasAudio || this.showSttPage || this.isRecording) return;
      this.$refs.audioElem.play();
      this.hasAudio = true;
      // let src = '';
      // if (typeof bgmSrc === 'string') {
      //   src = bgmSrc;
      // } else if (this.ques?.audio) {
      //   src = this.ques.audio;
      // }
      // // alert(typeof bgmSrc === 'string');
      // // alert(src);
      // if (!src) return;
      // setTimeout(() => {
      //   if (this.hasAudio || this.showSttPage || this.isRecording) return;
      //   try {
      //     const audio = new Audio(src);
      //     audio.setAttribute('crossorigin', 'anonymous');
      //     audio.loop = true;
      //     // alert('src');
      //     // alert(audio.src);
      //     window.playStoryBgm = audio;
      //     audio.play();
      //     this.hasAudio = true;
      //   } catch (error) {
      //     console.log('playBgm error', error);
      //   }
      // }, 200);
    },
    stopBgm() {
      this.$refs.audioElem.pause();
      // if (typeof window.playStoryBgm?.pause === 'function') {
      //   window.playStoryBgm.pause();
      // }
    },
    inputStt(e) {
      const stt = e.target.innerText;
      this.stt = stt;
    },
    emitEvent(ev, data) {
      if (this.socket) {
        this.socket.emit(ev, data);
      }
    },
    /**
     * 若未指定 query 則以當前的 stt 為主
     */
    async submitAnswer(query) {
      if (!this.loading) {
        this.loading = true;
        try {
          // eslint-disable-next-line no-param-reassign
          query = query || this.stt || '';
          // console.log(query, '?query');
          if (!query) {
            this.dialogMsg = '回答不能為空';
            this.showDialog = true;
            this.loading = false;
            return;
          }
          const results = await quesApi({
            sid: this.socket.id,
            quesId: this.quesId,
            query,
            nickname: this.nickname,
            email: this.email,
          });
          // console.log(results.errMsg);
          if (results.errMsg && results.errMsg.content) {
            this.dialogMsg = results.errMsg.content;
            this.showDialog = true;
            // alert(results.errMsg.content);
          } else {
            this.emitEvent(EV.user.set_state, {
              view: 'stt',
              stt: query,
              mbti: results,
            });
            this.$router.replace({
              path: '/kongkoo/user/panel',
              query: {
                convId: results.convId,
                roomId: this.roomId,
              },
            });
          }
        } catch (e) {
          console.error(e);
        }
        this.loading = false;
      }
    },
    startRecording() {
      this.stopBgm();
      this.isRecording = true;
      // const that = this;
      this.emitEvent(EV.user.set_state, { state: 'recording' });
      if (typeof navigator.mediaDevices.getUserMedia === 'function') {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((stream) => {
            const mediaRecorder = new MediaRecorder(stream);
            mediaRecorder.start();
            mediaRecorder.ondataavailable = (e) => {
              sttApi(e.data)
                .then((results) => {
                  console.log('stt:', results);
                  this.handleSttResult(results);
                })
                .catch((err) => {
                  console.error(err);
                });
            };
            mediaRecorder.onstop = function (params) {
              this.stream.getTracks().forEach((i) => i.stop());
            };
            this.mediaRecorder = mediaRecorder;
          })
          .catch((e) => {
            alert(`該設備可能無法授予錄音權限 ${e.toString()}`);
          });
      } else {
        alert('瀏覽器不支持 getUserMedia');
      }
    },
    async stopRecording() {
      this.isRecording = false;
      this.mediaRecorder.stop();
    },
    handleSttResult(sttRes) {
      if (sttRes) {
        this.stt = sttRes.text;
        this.showSttPage = true;
        this.$nextTick(() => {
          document.querySelector('#stt-box').innerText = sttRes.text;
        });
        this.emitEvent(EV.user.set_state, { state: 'stt_end', stt: sttRes.text });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrap {
  width: 100vw;
  height: 100vh;

  .main {
    position: relative;
    margin: auto;
    background: radial-gradient(
      49.98% 49.97% at 49.99% 50%,
      #e8e3dc 0%,
      #e9d7c1 77.76%,
      #dec4a4 100%
    );
    width: 428rem;
    height: 100%;
    .moveWrap {
      transform: translateY(0px);
      transition: 0.3s;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
    }
    .lantern-img {
      display: block;
      margin: 20rem auto 0;
      width: 80rem;
      height: auto;
    }
    .opening-sec {
      width: 95%;
      padding: 20rem;
      font-family: 'Noto Sans TC S';
      font-style: normal;
      font-weight: 400;
      font-size: 16rem;
      line-height: 2;
      color: #000000;
    }
    .desc-sec {
      width: 95%;
      height: 348rem;
      padding: 20rem;
      font-family: 'Noto Sans TC S';
      font-style: normal;
      font-weight: 400;
      font-size: 18rem;
      line-height: 34rem;
      text-align: justify;
      color: #580800;

      background: rgba(255, 255, 255, 0.8);
      border: 2rem solid #ffffff;
      box-sizing: border-box;
      border-radius: 10rem;
    }
    .question-sec {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 42rem auto 26rem;
      background-image: url('../../../assets/user/play/question-bg.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 95%;
      height: 172rem;
      padding: 20rem 40rem;
      font-family: 'Noto Sans TC B';
      font-style: normal;
      font-weight: 900;
      font-size: 18rem;
      line-height: 30rem;
      letter-spacing: 0.2em;
      color: #580800;
    }
    .stt-sec {
      // flex: 1;
      display: flex;
      flex-direction: column;
      width: 95%;
      .stt-title {
        text-align: center;
        font-family: 'Noto Sans TC B';
        font-style: normal;
        font-weight: 900;
        font-size: 22rem;
        line-height: 30rem;
        letter-spacing: 0.2em;
        color: #580800;
        margin: 0rem auto 15px;
      }
      .text-box {
        height: 130rem;
        padding: 20rem;
        background: #ffffff;
        box-shadow: inset 0rem 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10rem;
        font-family: 'Noto Sans TC S';
        font-style: normal;
        font-weight: 400;
        font-size: 18rem;
        line-height: 2;
        color: #000000;
        // flex: 1;
      }
    }
    .btn-wrap {
      position: relative;
    }
    .record-btn {
      width: 120rem;
      cursor: pointer;
      padding-bottom: 10rem;
    }
    .pen-btn {
      position: absolute;
      right: -80rem;
      bottom: 35rem;
      width: 50rem;
      cursor: pointer;
    }
    .disabled-btn {
      opacity: 0.6 !important;
      cursor: default !important;
    }
    .submit-btn {
      margin: 30rem 0;
      cursor: pointer;
      background-image: url('../../../assets/SvgGameScreen/btn-bg.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(265rem * 0.8);
      min-height: calc(76.85rem * 0.8);
      font-family: 'Noto Sans TC S';
      font-style: normal;
      font-weight: 900;
      font-size: 32rem;
      line-height: 0px;
      text-align: center;
      letter-spacing: 0.2em;
      color: #580800;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
</style>
