<template>
  <!-- <section> -->
  <svg v-bind="svgAttrs" @click="$emit('click', $event)">
    <!-- <rect x="0" y="0" :width="w" :height="h" :fill="backgroundColor" /> -->
    <g class="player" v-bind="playerAttrs">
      <SvgQuadBackground
        mode="R"
        :width="playerBlock.width"
        :height="playerBlock.height"
        :color="playerColor"
      />
      <image
        :href="img.player"
        :x="playerBlock.iconX"
        :y="playerBlock.iconY"
        :width="playerBlock.iconWidth"
      />
      <svg
        :x="playerBlock.nameX"
        :y="playerBlock.nameY"
        :width="playerBlock.tWidth"
        :height="playerBlock.tHeight"
      >
        <text x="0" y="30" class="player-name" text-anchor="start" :font-size="fontSize.playerName">
          {{ playerName }}
        </text>
      </svg>
    </g>
    <g class="bg" v-bind="bgAttrs">
      <SvgQuadBackground :width="bgBlock.width" :height="bgBlock.height" :color="bgBlock.color" />
    </g>

    <g class="vec-title-group" v-bind="vecTitleAttrs">
      <!-- 確認位置用的方塊 -->
      <!-- <rect
        :x="contentBlock.mid"
        :y="contentBlock.yList[0]"
        width="100"
        height="100"
        stroke="red"
      /> -->
      <template v-for="(text, i) in vecData">
        <text
          v-if="view === 'mbti'"
          :x="contentBlock.sTextXL"
          :y="contentBlock.yList[i]"
          :font-size="contentBlock.fontSize"
          :key="text.sTitleL"
          class="vec-title"
        >
          {{ text.sTitleL }}
        </text>
        <text
          v-if="view === 'mbti'"
          :x="contentBlock.textXL"
          :y="contentBlock.yList[i]"
          :font-size="contentBlock.fontSize"
          :key="text.titleL"
          class="vec-title"
        >
          {{ text.titleL }}
        </text>
        <text
          v-if="view === 'mbti'"
          :x="contentBlock.sTextXR"
          :y="contentBlock.yList[i]"
          :font-size="contentBlock.fontSize"
          :key="text.sTitleR"
          class="vec-title"
        >
          {{ text.sTitleR }}
        </text>
        <text
          v-if="view === 'mbti'"
          :x="contentBlock.textXR"
          :y="contentBlock.yList[i]"
          :font-size="contentBlock.fontSize"
          :key="text.titleR"
          class="vec-title"
        >
          {{ text.titleR }}
        </text>
      </template>
    </g>
    <g class="vec-bar-group" v-bind="vecBarAttrs" v-if="view === 'mbti'">
      <template v-for="(vec, i) in vecData">
        <SvgValueBar
          :key="vec.sTitleL"
          :x="contentBlock.barXL"
          :y="contentBlock.yList[i]"
          :width="contentBlock.barWidth"
          :height="contentBlock.barHeight"
          :value="vec.valueL"
          :color="vec.colorL"
          direction="R"
        />
        <SvgValueBar
          :key="vec.sTitleR"
          :x="contentBlock.barXR"
          :y="contentBlock.yList[i]"
          :width="contentBlock.barWidth"
          :height="contentBlock.barHeight"
          :value="vec.valueR"
          :color="vec.colorR"
          direction="L"
        />
      </template>
    </g>
    <!-- 現在沒有 pathTag -->
    <!-- <g class="path-tag-group" transform="translate(188, 446)">
        <SvgPathTag color="#FF9CD2" :msg="'#' + player.pathTags[0]" v-if="player.pathTags[0]" />
        <SvgPathTag
          color="#C4B0FF"
          :x="320"
          :msg="'#' + player.pathTags[1]"
          v-if="player.pathTags[1]"
        />
      </g> -->
    <!-- <rect
      :x="contentBlock.x"
      :y="contentBlock.y"
      :width="contentBlock.width"
      height="800"
      fill="none"
      stroke="blue"
    /> -->

    <!-- 底下測試用的網格 -->
    <!-- <g v-if="grid && grid.length > 0">
      <template v-for="row in grid">
        <template v-for="block in row">
          <rect
            :key="`${block.x}-${block.y}`"
            :x="block.x"
            :y="block.y"
            width="100"
            height="100"
            fill="none"
            stroke="black"
          />
        </template>
      </template>
    </g> -->
  </svg>
  <!-- </section> -->
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-param-reassign */
/* eslint-disable global-require */
import SvgQuadBackground from './SvgQuadBackground.vue';
import SvgPathTag from './SvgPathTag.vue';
import SvgValueBar from './SvgValueBar.vue';

export default {
  components: {
    SvgQuadBackground,
    SvgValueBar,
    SvgPathTag,
  },
  props: {
    playerColor: {
      type: String,
      default: '#786BC8',
    },
    sNum: {
      type: Number,
    },
  },
  computed: {
    info() {
      return this.$store.state.kongkoo;
    },
    screen() {
      const { players } = this.info;
      return players[this.sNum];
    },
    view() {
      return this?.player?.view;
      // return 'process' | 'stt'
    },
    player() {
      const { players } = this.info;
      return players[this.sNum];
    },
    screenId() {
      const { screenId } = this.$store.state.kongkoo;
      return screenId;
    },
    storyId() {
      const { screenId } = this;
      if (screenId === 'screen_0') {
        return 'KONGKOO_1';
      }
      if (screenId === 'screen_1') {
        return 'KONGKOO_2';
      }
      if (screenId === 'screen_2') {
        return 'KONGKOO_3';
      }
      console.log('W:', screenId);
      return 'KONGKOO_3';
    },
    playerName() {
      const { player } = this;
      if (!player.join) {
        return '未加入...';
      }
      if (!player.nickname) {
        return `匿名用戶${this.sNum + 1}`;
      }
      return player.nickname;
    },
    // backgroundColor() {
    //   return this.screen.backgroundColor;
    // },
    svgAttrs() {
      const { dev } = this.info;
      const { w, h } = this;
      const obj = {
        width: w,
        height: h,
        viewBox: [0, 0, w, h].join(' '),
        preserveAspectRatio: 'none',
        version: '1.1',
        xmlns: 'http://www.w3.org/2000/svg',
      };
      if (dev) {
        delete obj.preserveAspectRatio;
      }
      return obj;
    },
    bgAttrs() {
      const { bgBlock } = this;
      const obj = {
        transform: `translate(${bgBlock.x},${bgBlock.y})`,
      };
      return obj;
    },
    vecTitleAttrs() {
      const { contentBlock } = this;
      const obj = {
        transform: `translate(${contentBlock.x},${contentBlock.y})`,
      };
      return obj;
    },
    vecBarAttrs() {
      const { contentBlock } = this;
      const obj = {
        transform: `translate(${contentBlock.x},${contentBlock.barY})`,
      };
      return obj;
    },
    playerAttrs() {
      const { playerBlock } = this;
      const obj = {
        transform: `translate(${playerBlock.x},${playerBlock.y})`,
      };
      return obj;
    },
    // #2
    playerBlock() {
      // 顯示username的那個小方塊
      const { w, h } = this;
      const fontSize = 25;
      const padding = 15;
      const width = 258;
      const height = 95;
      const tWidth = 130;
      const tHeight = 40;
      const x = w * 0.025;
      const y = h * 0.09;
      const iconY = padding + 5;
      const iconX = padding + 10;
      const iconWidth = 30;
      const gap = 15;
      const nameX = iconX + iconWidth + gap;
      const nameY = 20;
      return {
        x,
        y,
        width,
        height,
        tWidth,
        tHeight,
        nameX,
        nameY,
        iconX,
        iconY,
        iconWidth,
        padding,
        fontSize,
      };
    },
    // #2
    bgBlock() {
      const { w, h } = this;
      return {
        x: w * 0.025,
        y: h * 0.25,
        width: w * 0.95,
        height: h * 0.8,
        // width: 200,
        // height: 50,
        padding: 30,
        color: '#FFF',
      };
    },
    // #2
    contentBlock() {
      const { w, h, bgBlock } = this;
      // 相對畫面中心去做處理
      const {
        width: bgW, height: bgH, y: bgY, x: bgX, padding: bgPadding,
      } = bgBlock;
      const {
        // 兩個中文字的寬
        tWidth,
        // 中文字的高
        tHeight,
        // 英文字母的寬
        stWidth: sWidth,
      } = this.textM;

      const barWidth = 250;
      const barHeight = 50;
      // 左右位置只依據 mid
      const mid = w / 2;
      const fontSize = this.fontSize.vecTitle;
      const midGap = 5;
      const barXL = mid - midGap - barWidth;
      const barXR = mid + midGap;
      // 文字跟 bar 的間距
      const barTextGap = 20;
      // 字母跟中文的間距
      const wordGap = 15;
      const lineGap = 40;

      const textXL = barXL - barTextGap - tWidth;
      const sTextXL = textXL - wordGap - sWidth;
      const sTextXR = barXR + barWidth + barTextGap - 10;
      const textXR = sTextXR + wordGap + sWidth;
      const width = (mid - sTextXR) * 2;

      const yStart = 0;
      const yList = [];
      for (let i = 0; i < 4; i += 1) {
        yList.push(yStart + i * (lineGap + tHeight));
      }
      const x = -20;
      const y = bgY + bgPadding + 30;
      const barY = y - 35;

      return {
        mid,
        x,
        y,
        barXL,
        barXR,
        barY,
        barWidth,
        barHeight,
        textXR,
        textXL,
        sTextXL,
        sTextXR,
        fontSize,
        yList,
        width,
      };
    },
    tagBlock() {
      const { w, h } = this;
      const colors = ['#FF9CD2', '#C4B0FF'];
      const gap = 60;
      const mid = w / 2;
      const y = h * 0.95;
      const pos = [];
      return {
        mid,
        y,
      };
    },
    grid() {
      const { w, h } = this;
      const baseX = (w % 100) / 2;
      const baseY = (h % 100) / 2;
      const matrix = [];
      for (let i = -100; i < w; i += 100) {
        const row = [];
        for (let j = -100; j < h; j += 100) {
          row.push({ x: i + baseX, y: j + baseY });
        }
        matrix.push(row);
      }
      return matrix;
    },
    textM() {
      const nameFontSize = this.fontSize.playerName;
      const vTitleFontSize = this.fontSize.vecTitle;
      const nameMatrix = this.measureText('中文', nameFontSize);
      const nHeight = nameMatrix.actualBoundingBoxAscent + nameMatrix.actualBoundingBoxDescent;
      const tMatrix = this.measureText('中文', vTitleFontSize);
      const sMatrix = this.measureText('Ｎ', vTitleFontSize);
      // 兩個中文字的寬
      const tWidth = tMatrix.width;
      // 兩個中文字的高
      const tHeight = tMatrix.actualBoundingBoxAscent + tMatrix.actualBoundingBoxDescent;
      const stWidth = sMatrix.width;
      return {
        tWidth,
        tHeight,
        nHeight,
        stWidth,
      };
    },
  },

  watch: {
    // eslint-disable-next-line func-names
    'player.mbti': function (mbti) {
      console.log('update mbti');
      const obj = mbti.summary;
      const v1 = -1 * this.parseMbtiValue(obj['外向']);
      const v2 = this.parseMbtiValue(obj['直覺']);
      const v3 = -1 * this.parseMbtiValue(obj['理性']);
      const v4 = -1 * this.parseMbtiValue(obj['熟思']);
      const vals = [v1, v2, v3, v4];
      console.log(obj, vals);
      for (let i = 0; i < 4; i += 1) {
        const v = vals[i] * 100;
        const vec = this.vecData[i];
        if (v > 0) {
          vec.valueR = v;
          vec.valueL = 0;
        } else {
          vec.valueL = -v;
          vec.valueR = 0;
        }
      }
    },
  },
  data() {
    const w = 888;
    const h = 366;
    // const w = 960;
    // const h = 540;
    return {
      w,
      h,
      img: {
        player: require('@/assets/player-icon.svg'),
        bg: require('@/assets/game-bg.png'),
      },
      fontSize: {
        playerName: 25,
        vecTitle: 25,
      },
      // 這個要隨著
      chWordWidth: 41.6,
      titleGap: 40,
      lineHeight: 15,
      vecData: [
        {
          sTitleL: 'Ｅ',
          titleL: '外向',
          sTitleR: 'Ｉ',
          titleR: '內向',
          colorL: '#FF5981',
          colorR: '#FF5981',
          valueL: 0,
          valueR: 0,
        },
        {
          sTitleL: 'Ｓ',
          titleL: '務實',
          sTitleR: 'Ｎ',
          titleR: '直覺',
          colorL: '#FFE600',
          colorR: '#FFE600',
          valueL: 0,
          valueR: 0,
        },
        {
          sTitleL: 'Ｔ',
          titleL: '理性',
          sTitleR: 'Ｆ',
          titleR: '感性',
          colorL: '#4DDC8F',
          colorR: '#4DDC8F',
          valueL: 0,
          valueR: 0,
        },
        {
          sTitleL: 'Ｊ',
          titleL: '條理',
          sTitleR: 'Ｐ',
          titleR: '靈活',
          colorL: '#6BB8FF',
          colorR: '#6BB8FF',
          valueL: 0,
          valueR: 0,
        },
      ],
    };
  },
  created() {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    this.$canvas = canvas;
    this.$ctx = ctx;
  },
  mounted() {
    // console.log('========');
    // console.log(this.measureText('abc', 30));
    // console.log(this.measureText('Ｉ內向', 30));
  },
  beforeDestroy() {
    if (this.$audio) {
      this.$audio.pause();
      this.$audio = null;
    }
  },
  methods: {
    measureText(text, size, weight) {
      /** @type {CanvasRenderingContext2D} */
      // console.log('aaaa', this.$ctx, this.$canvas);
      const ctx = this.$ctx;
      weight = weight || 'bold';
      ctx.font = `${weight} ${size}px 'Noto Sans TC S', 'Noto Sans TC'`;
      return ctx.measureText(text);
    },
    parseMbtiValue(v) {
      return 2 * v;
    },
    // onClickSVG($event) {
    //   const { storyId } = this;
    //   this.$audio = this.$audio || new Audio();
    //   const audio = this.$audio;
    //   const url = `https://storage.googleapis.com/voiss-kongkoo/story_data/${storyId}/bgm.mp3`;
    //   audio.src = url;
    //   audio.play();
    //   this.$emit('click', $event);
    // },
  },
};
</script>

<style lang="scss" scoped>
.stt-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 300px;
  background: black;
}
svg {
  width: 100%;
  font-family: 'Noto Sans TC S', 'Noto Sans TC';
  .vec-title {
    font-weight: bold;
    fill: #575755;
    stroke: none;
  }
  .player-name {
    font-weight: bold;
    fill: #fff;
    stroke: none;
  }
}
</style>
