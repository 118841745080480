<template>
  <div class="displayer">
    <!-- <audio
        loop
        style="display: none"
        ref="audioElem"
        controls
        id="hide-audio"
        src="@/static/music/bgm.mp3"
        @loadeddata="audioOnloadEv"
      ></audio> -->
    <template v-if="ready">
      <img
        src="@/assets/prev-btn.png"
        v-show="view === 'prepare'"
        class="prev-btn"
        @click="goToStoryList"
      />
      <img
        src="@/assets/prev-btn.png"
        v-show="view !== 'prepare'"
        class="prev-btn"
        @click="backToHome"
      />
      <img
        v-show="hasBgm && audioOnloadOk && (view === 'game' || view === 'end')"
        class="audio-icon"
        src="@/assets/music.png"
        alt=""
        @click.prevent.stop="pauseBgm"
      />
      <img
        v-show="!hasBgm && audioOnloadOk && (view === 'game' || view === 'end')"
        class="audio-icon"
        src="@/assets/no-music.png"
        alt=""
        @click.prevent.stop="playBgm"
      />
      <template v-if="view === 'prepare'">
        <SvgPrepareScreen @playBgm="playBgm"></SvgPrepareScreen>
      </template>
      <template v-if="view === 'landing'">
        <transition name="fade">
          <SvgLandingScreen></SvgLandingScreen>
        </transition>
      </template>
      <template v-if="view === 'game'">
        <transition name="fade">
          <div class="game-page">
            <div class="story-title-sec">
              <img class="icon-img" src="@/assets/SvgGameScreen/story-title-icon-1.png" alt="" />
              <div class="text-box" :class="{ 'when-btn-none': !showHeaderBtn }">
                <span v-if="ques">{{ ques.title + ques.slogan }}</span>
              </div>
              <div class="btn-box" v-if="showHeaderBtn" @click="btnPrimary">
                {{ primaryBtnText }}
              </div>
            </div>
            <div class="m-displayer">
              <!-- <div > -->
              <template v-for="player in players">
                <div
                  class="stt-div-wrap"
                  v-show="player.view === 'stt'"
                  :key="`div-${player.sNum}`"
                  @click.stop="btnGameScreen($event, player)"
                >
                  <div class="stt-div">
                    <span v-if="!player.join"></span>
                    <span v-else-if="player.state === 'recording'"> 錄音中... </span>
                    <span v-else-if="player.state === 'stt_end'">
                      {{ player.stt || '編輯中...' }}
                    </span>
                    <span v-else>
                      {{ player.stt || '等待講話...' }}
                    </span>
                  </div>
                </div>
                <SvgGameScreen
                  :sNum="player.sNum"
                  class="svg-container"
                  :playerColor="playerColors[player.sNum]"
                  :key="`sc-${player.sNum}`"
                  @click.stop="btnGameScreen($event, player)"
                />
              </template>
              <!-- </div> -->
            </div>
          </div>
        </transition>
      </template>
      <template v-if="view === 'end'">
        <SvgEndScreen></SvgEndScreen>
      </template>
    </template>
    <template v-else>
      <div class="message">
        <span>
          {{ msg }}
        </span>
      </div>
    </template>
  </div>
</template>

<script>
// import { wsAttrs } from '@/mixins/kongkoo-ws';
import {
  EV, M, A, ns,
} from '@/lib/kongkooV2-def';
import SvgPrepareScreen from '@/components/kongkoo/SvgPrepareScreen.vue';
import SvgGameScreen from '@/components/kongkoo/SvgGameScreen.vue';
import SvgEndScreen from '@/components/kongkoo/SvgEndScreen.vue';
import SvgLandingScreen from '@/components/kongkoo/SvgLandingScreen.vue';

const bgmSrc = require('@/static/music/bgm.mp3');

export default {
  layout: 'empty',
  components: {
    SvgPrepareScreen,
    SvgGameScreen,
    SvgEndScreen,
    SvgLandingScreen,
  },
  computed: {
    view() {
      // return 'game'; // TODO: 上版前註解這行
      const { view } = this.$store.state.kongkoo;
      return view;
    },
    players() {
      const { players } = this.$store.state.kongkoo;
      return players;
    },
    socket() {
      const { socket } = this.$store.state.kongkoo;
      return socket;
    },
    ques() {
      const { ques } = this.$store.state.kongkoo;
      return ques;
    },
    /**
     * 已完成的玩家列表
     */
    completedPlayers() {
      const { players } = this;
      const completedPlayers = players.filter(
        (player) => player.join && player.mbti && player.panelData,
      );
      return completedPlayers;
    },
    showHeaderBtn() {
      // return true; // TODO: 上版前註解這行
      const { completedPlayers } = this;
      return completedPlayers.length >= 1;
    },
  },
  watch: {
    'players.0.mbti': {
      deep: true,
      handler(mbti) {
        this.handlePlayerMbtiChange(0, mbti);
      },
    },
    'players.1.mbti': {
      deep: true,
      handler(mbti) {
        this.handlePlayerMbtiChange(1, mbti);
      },
    },
    'players.2.mbti': {
      deep: true,
      handler(mbti) {
        this.handlePlayerMbtiChange(2, mbti);
      },
    },
    'players.3.mbti': {
      deep: true,
      handler(mbti) {
        this.handlePlayerMbtiChange(3, mbti);
      },
    },
  },
  data() {
    return {
      ready: false,
      playerColors: ['#AB3D3D', '#D28933', '#A6A368', '#68A6A2'],
      quesId: '',
      roomId: '',
      msg: '跨世代穿梭中......',
      primaryBtnText: '繼續',
      hasBgm: false,
      audioOnloadOk: false,
      // showHeaderBtn: true,
    };
  },
  async mounted() {
    const { roomId, quesId } = this.$route.query;
    this.roomId = roomId || 'default';
    this.quesId = quesId || 'S01';
    await this.init();
    // 結束遊戲的快捷鍵
    // const onKeyup = (e) => {
    //   if (e.ctrlKey && e.code === 'KeyQ') {
    //     if (this.view === 'game') {
    //       this.$store.dispatch(ns('kongkoo', A.endGame));
    //     }
    //   }
    // };
    // this.$onKeyup = onKeyup;
    // window.addEventListener('keyup', onKeyup);
    try {
      const audio = new Audio(bgmSrc);
      audio.setAttribute('crossorigin', 'anonymous');
      audio.loop = true;
      window.bigScreenStartBgm = audio;
      audio.addEventListener('loadeddata', () => {
        console.log('????????');
        this.audioOnloadOk = true;
        const { userAgent } = navigator;
        if (userAgent.indexOf('Firefox') > -1) {
          // 判断是否Firefox浏览器
          this.playBgm();
        }
      });
    } catch (error) {
      console.log('playBgm error', error);
    }
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.$onKeyup);
  },
  methods: {
    goToStoryList() {
      window.location.replace('https://kongkoo.co/storySwiper');
    },
    handlePlayerMbtiChange(num, mbti) {
      console.log('handlePlayerMbtiChange', num, mbti);
      setTimeout(() => {
        if (this.view === 'game') {
          this.$store.commit(ns('kongkoo', M.SET_PLAYER_STATE), {
            view: 'mbti',
            sid: this.players[num].sid,
          });
        }
      }, 3000);
    },
    backToHome() {
      const { socket, $store } = this;
      for (let i = 0; i < 4; i += 1) {
        $store.commit(ns('kongkoo', M.PLAYER_RESET), i);
      }
      if (socket) {
        socket.emit(EV.control.back_home);
      }
      $store.commit(ns('kongkoo', M.TO), 'prepare');
    },
    pauseBgm() {
      if (!this.hasBgm) return;
      window.bigScreenStartBgm.pause();
      this.hasBgm = false;
    },
    playBgm() {
      if (this.hasBgm) return;
      try {
        window.bigScreenStartBgm.play();
        this.hasBgm = true;
        this.audioOnloadOk = true;
      } catch (error) {
        console.log(error, 'playBgm err');
      }
    },
    // playBgm() {
    //   if (this.hasBgm) return;
    //   try {
    //     const audio = new Audio(bgmSrc);
    //     audio.setAttribute('crossorigin', 'anonymous');
    //     audio.loop = true;
    //     window.bigScreenStartBgm = audio;
    //     audio.play();
    //     this.hasBgm = true;
    //   } catch (error) {
    //     console.log('playBgm error', error);
    //   }
    // },
    async init() {
      const opts = {
        type: 'screen',
        roomId: this.roomId,
        quesId: this.quesId,
      };
      try {
        const socket = await this.$store.dispatch(ns('kongkoo', A.initConnection), opts);
        console.log('[server connected]', opts.type);
        window.socket = socket;
        await this.$store.dispatch(ns('kongkoo', A.initDisplayerEvents));
        this.ready = true;
      } catch (err) {
        if (err.code === 'PROMOTION_FAILED') {
          this.msg = '連結可能有誤....';
        }
      }
    },
    async btnPrimary() {
      const { view, roomId } = this;
      if (view === 'game') {
        this.$router.push({
          path: '/kongkoo/swiperPage',
          query: {
            roomId,
          },
        });
      }
    },
    async btnGameScreen($event, player) {
      if (player.view === 'stt' && player.mbti) {
        // eslint-disable-next-line no-param-reassign
        player.view = 'mbti';
      } else if (player.view === 'mbti') {
        // eslint-disable-next-line no-param-reassign
        player.view = 'stt';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.prev-btn {
  z-index: 5;
  cursor: pointer;
  width: 70px;
  position: absolute;
  left: 24px;
  top: 24px;
}
.audio-icon {
  z-index: 5;
  cursor: pointer;
  width: 70px;
  position: absolute;
  right: 24px;
  top: 24px;
}
.displayer {
  width: 100vw;
  height: 100vh;
  background: radial-gradient(
    49.98% 49.97% at 49.99% 50%,
    #e8e3dc 0%,
    #e9d7c1 77.76%,
    #dec4a4 100%
  );
}
.game-page {
  width: 100vw;
  height: 100vh;
  background: radial-gradient(
    49.98% 49.97% at 49.99% 50%,
    #e8e3dc 0%,
    #e9d7c1 77.76%,
    #dec4a4 100%
  );
  display: flex;
  .story-title-sec {
    position: absolute;
    height: 133px;
    width: 84%;
    left: 0;
    right: 0;
    margin: 40px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon-img {
      position: absolute;
      z-index: 1;
      left: 0;
      width: 144px;
    }
    .text-box {
      position: relative;
      left: 25px;
      margin-right: 20px;
      display: flex;
      align-items: center;
      // flex: 1;
      background-image: url('../../assets/SvgGameScreen/story-title-bg.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      font-family: 'Noto Sans TC B';
      font-style: normal;
      font-weight: 900;
      font-size: 30px;
      line-height: 60px;
      background-position: 0 50%;
      letter-spacing: 0.2em;
      color: #580800;
      padding: 0 20px 0 80px;
      height: 133px;
      width: 79.3%;
      &.when-btn-none {
        width: 89.3%;
      }
    }
    .btn-box {
      left: 20px;
      padding: 15px 30px;
      white-space: nowrap;
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-image: url('../../assets/SvgGameScreen/btn-bg.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      font-family: 'Noto Sans TC B';
      font-style: normal;
      font-weight: 900;
      font-size: 35px;
      text-align: center;
      letter-spacing: 0.2em;
      color: #580800;
    }
  }
  .m-displayer {
    width: 95%;
    height: calc(100vh - 200px);
    display: flex;
    flex-wrap: wrap;
    margin: auto auto 20px;
    // stt div start，div的大小和位置估計可以寫死不用傳props
    .stt-div-wrap {
      width: 100%;
      height: 200px;
      position: absolute;
    }
    .stt-div-wrap:nth-of-type(1) {
      top: calc(133px + 16%);
      left: 0;
      .stt-div {
        // height: 100%;
        // width: 45.5%;
        // background: white;
        // position: absolute;
        // left: 11%;
        height: 100%;
        // background: white;
        position: absolute;
        left: 4.7%;
      }
    }
    .stt-div-wrap:nth-of-type(2) {
      top: calc(133px + 16%);
      left: 0;
      .stt-div {
        height: 100%;
        // background: white;
        position: absolute;
        left: 52%;
      }
    }
    .stt-div-wrap:nth-of-type(3) {
      top: calc(133px + 58%);
      left: 0;
      .stt-div {
        height: 100%;
        // background: white;
        position: absolute;
        left: 4.7%;
      }
    }
    .stt-div-wrap:nth-of-type(4) {
      top: calc(133px + 58%);
      left: 0;
      .stt-div {
        height: 100%;
        // background: white;
        position: absolute;
        left: 52%;
      }
    }
    .stt-div-wrap .stt-div {
      font-family: 'Noto Sans TC S';
      width: 42.5%;
      font-style: normal;
      font-weight: 900;
      font-size: 2rem;
      letter-spacing: 0.1em;
      padding: 15px;
      color: #580800;
      word-wrap: break-word;
    }
    // stt div end
  }
}
.svg-container {
  display: flex;
  flex: 0 0 auto;
  width: 50%;
  height: 50%;
  overflow: visible;
}
.svg-screen {
  width: 100%;
  height: 100%;
}
.message {
  display: flex;
  width: 100%;
  font-size: 60px;
  justify-content: center;
  align-self: center;
  color: #777;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
