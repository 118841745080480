/**
 * 連線相關的部分常用的 computed 屬性
 */
export const wsAttrs = {
  computed: {
    user() {
      return this.$store.state.kongkoo.user;
    },
    socket() {
      return this.$store.state.kongkoo.socket;
    },
    socketId() {
      return this.$store.state.kongkoo.socketId;
    },
    ques() {
      return this.$store.state.kongkoo.ques;
    },
    previewPage() {
      return !!this.$route.query.preview;
    },
  },
};

/**
 * 用在必須要在連線狀態下使用的頁面
 */
export const requireConnection = {
  mounted() {
    // 檢查有沒有連線
    if (!this.socket && !this.$route.query.preview) {
      // 沒連線 & 不是預覽模式
      // 回到登入頁面
      this.$router.replace('/kongkoo/user/signIn');
    }
  },
};
