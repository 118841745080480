<template>
  <div class="wrap" @click="onPanelClick">
    <div class="turn-sec" :class="{ turn: isTurn }">
      <div class="aContent" ref="front">
        <img id="bindFrontOuterBg" class="outer-bg" :src="bindFrontOuterBg" />
        <img id="bindFrontInnerBg" class="inner-bg" :src="bindFrontInnerBg" />
        <div class="avatar-box">
          <img id="bindFrontAvatar" :src="bindFrontAvatar" alt="" class="avatar" />
        </div>
        <p class="info-text" v-html="frontMiniText"></p>
        <div class="story-board-sec">
          <p class="p1">{{ frontTitle }}</p>
          <p class="p2">{{ frontSubtitle }}</p>
          <p class="p3" @click="openKongKooPage">{{ frontLinkText }}</p>
        </div>
        <div class="ribbon-sec">
          <p class="p1">{{ username }}</p>
          <p class="p2">跨世代的對話明信片</p>
        </div>
        <p class="date">{{ frontDate }}</p>
      </div>
      <!-- 背面 -->
      <div class="bContent-rotate-box">
        <div class="bContent" ref="back">
          <img id="bindBackBg" :src="bindBackBg" alt="" />
          <div class="outer-bg"></div>
          <p class="username">{{ username }}</p>
          <p class="info-text">– 你獲得的專屬文化角色人格是 –</p>
          <div class="ai-box">
            <p class="yellow-text">AI 認證</p>
            <img id="bindBackImg" :src="bindBackImg" alt="" />
            <p class="ai-title" v-html="backImgTitle"></p>
          </div>
          <p class="ai-desc" v-html="backDesc1"></p>
          <section class="stt-box">
            <img class="stt-icon" src="@/assets/panel/b-content-stt-icon.png" alt="" />
            <div class="stt-text">{{ backText }}</div>
          </section>
          <p class="type-desc" v-html="backDesc2"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JSZip from 'jszip';

// 雙面的可翻頁的明信片
export default {
  name: 'TurnAblePanel',
  props: {
    panelData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    // 下載明信片參考 kongkoo 專案的 PostcardLandingPage.vue
    return {
      showDialog: false,
      dialogMsg: '',
      isTurn: false,
      bindFrontInnerBg: '',
      bindFrontOuterBg: '',
      bindFrontAvatar: '',
      bindBackImg: '',
      bindBackBg: '',
    };
  },
  computed: {
    frontTitle() {
      return this.panelData?.front?.title;
    },
    frontMiniText() {
      const text = this.panelData?.front?.miniText;
      if (text && text.length > 12) {
        return `${text.slice(0, 12)}<br/>${text.slice(12)}`;
      }
      return this.panelData?.front?.miniText;
    },
    frontSubtitle() {
      return this.panelData?.front?.subtitle;
    },
    frontLinkText() {
      return this.panelData?.front?.linkText;
    },
    frontDate() {
      return this.panelData?.front?.date;
    },
    backImgTitle() {
      return this.panelData?.back?.imgTitle;
    },
    backDesc1() {
      return this.panelData?.back?.desc1;
    },
    backDesc2() {
      const text = this.panelData?.back?.desc2;
      if (text && text.length > 30) {
        return `${text.slice(0, 30)}...`;
      }
      return this.panelData?.back?.desc2;
    },
    backText() {
      const text = this.panelData?.back?.text;
      if (text && text.length > 29) {
        return `${text.slice(0, 29)}...`;
      }
      return this.panelData?.back?.text;
    },
    username() {
      return this.panelData?.username;
    },
  },
  watch: {
    panelData: {
      immediate: true,
      handler(data) {
        const frontInnerBg = data?.front?.innerBg;
        if (frontInnerBg) this.bindFrontInnerBg = frontInnerBg;
        const frontOuterBg = data?.front?.outerBg;
        if (frontOuterBg) this.bindFrontOuterBg = frontOuterBg;
        const frontAvatar = data?.front?.avatar;
        if (frontAvatar) this.bindFrontAvatar = frontAvatar;
        const backImg = data?.back?.img;
        if (backImg) this.bindBackImg = backImg;
        const backBg = data?.back?.bg;
        if (backBg) this.bindBackBg = backBg;
      },
    },
  },
  methods: {
    openKongKooPage() {
      window.open('http://kongkoo.co/');
    },
    onPanelClick($event) {
      this.$emit('click', $event);
    },
    toggleTurn() {
      this.isTurn = !this.isTurn;
    },
    imgHttpsToBase64(img) {
      const canvas = document.createElement('canvas');
      window.img = img;
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight);
      const dataURL = canvas.toDataURL('image/png');
      return dataURL;
    },
    dataURLtoFile(dataurl, filename = 'result') {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n) {
        n -= 1;
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    async getCameraActionData() {
      const elem = this.isTurn ? this.$refs.back : this.$refs.front;
      const canvas = await this.$html2canvas(elem);
      document.documentElement.appendChild(canvas);
      const dataURL = canvas.toDataURL('image/png');
      return dataURL;
    },
    async doActionDownload({ doDLNext = true } = {}) {
      try {
        if (doDLNext) {
          this.$emit('update:showDialog', true);
        }
        const isIOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        const imgData = await this.getCameraActionData();
        const fileName1 = this.isTurn ? 'a-panel.png' : 'b-panel.png';
        if (isIOS) {
          const fileName2 = !this.isTurn ? 'a-panel.png' : 'b-panel.png';
          this.isTurn = !this.isTurn;
          await this.delay(700);
          const imgData2 = await this.getCameraActionData();
          const img1 = imgData.replace(/^data:image\/png;base64,/, '');
          const img2 = imgData2.replace(/^data:image\/png;base64,/, '');
          const zip = new JSZip();
          zip.file(fileName1, img1, { base64: true });
          zip.file(fileName2, img2, { base64: true });
          const zipBlob = await zip.generateAsync({ type: 'blob' });
          const data = await this.blobToDataURL(zipBlob);
          this.saveFile('明信片.zip', data);
        } else {
          // android 下載一面之後自動下載另一面
          // no IOS
          this.saveFile(this.isTurn ? 'a-panel.png' : 'b-panel.png', imgData);
          this.isTurn = !this.isTurn;
          if (doDLNext) {
            await this.delay(700);
            await this.doActionDownload({
              doDLNext: false,
            });
          }
        }
      } catch (error) {
        console.error(error, 'doActionDownload');
        if (error?.response?.data?.message) {
          alert(error.response.data.message);
        }
      }
    },
    saveFile(name, fileData) {
      const link = document.createElement('a');
      link.href = fileData;
      link.download = name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    blobToDataURL(blob) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onload = (e) => {
          resolve(e.target.result);
        };
        fr.onerror = reject;
        fr.readAsDataURL(blob);
      });
    },
    delay(t) {
      return new Promise((resolve) => {
        setTimeout(resolve, t);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  perspective: 800rem;
}
.turn-sec {
  margin: auto;
  width: 400rem;
  height: 600rem;
  transform-style: preserve-3d;
  transform: rotateY(0deg);
  transition: 0.3s;
  &.turn {
    transform: rotateY(-180deg);
  }
  .aContent,
  .bContent {
    width: 100%;
    // height: 100%;
    height: 600rem;
    & * {
      z-index: 2;
    }
  }
  .aContent {
    transform: translateZ(1rem);
    #bindFrontOuterBg {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 0;
    }
    #bindFrontInnerBg {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 376.05rem;
      height: 565.6rem;
    }
    .avatar-box {
      display: flex;
      position: absolute;
      top: 15rem;
      right: 15rem;
      width: 136rem;
      height: 168rem;
      background-image: url('../assets/panel/a-content-avatar-bg.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      .avatar {
        margin: auto;
        width: 90%;
        height: 90%;
        // object-fit: cover;
      }
    }
    .info-text {
      position: absolute;
      top: 56rem;
      left: 40rem;
      font-family: 'Noto Sans TC S';
      font-style: normal;
      font-weight: 700;
      font-size: 12rem;
      line-height: 2;
      letter-spacing: 1px;
      color: #000000;
    }
    .story-board-sec {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 318rem;
      height: 159rem;
      margin: auto;
      background-image: url('../assets/panel/a-content-story-name-bg.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .p1 {
        font-family: 'Noto Sans TC B';
        font-style: normal;
        font-weight: 900;
        font-size: 28rem;
        line-height: 40rem;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 1px;

        color: #5b4035;
      }
      .p2 {
        font-family: 'Noto Sans TC B';
        font-style: normal;
        font-weight: 900;
        font-size: 18rem;
        line-height: 26rem;
        /* identical to box height */

        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 1px;

        color: #000000;
        margin: 11rem auto 16rem;
      }
      .p3 {
        cursor: pointer;
        font-family: 'Noto Sans TC S';
        font-style: normal;
        font-weight: 500;
        font-size: 12rem;
        line-height: 14rem;
        /* identical to box height */

        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 1px;
        text-decoration-line: underline;

        color: #878787;
      }
    }
    .ribbon-sec {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 43rem;
      margin: auto;
      width: 358rem;
      height: 116rem;
      background-image: url('../assets/panel/a-content-ribbon.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      .p1 {
        margin: 15rem auto 5rem;
        font-family: 'Noto Sans TC S';
        font-style: normal;
        font-weight: 700;
        font-size: 18rem;
        line-height: 24rem;
        /* identical to box height, or 133% */

        display: flex;
        align-items: center;
        letter-spacing: 1px;

        color: #8f4d45;
      }
      .p2 {
        font-family: 'Noto Sans TC S';
        font-style: normal;
        font-weight: 700;
        font-size: 12rem;
        line-height: 24rem;
        /* identical to box height, or 200% */

        display: flex;
        align-items: center;
        letter-spacing: 1px;
        color: #ae9068;
      }
    }
    .date {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 47.61rem;
      margin: auto;
      font-family: 'Noto Sans TC S';
      font-style: normal;
      font-weight: 500;
      font-size: 16rem;
      line-height: 30rem;
      color: #8f4d45;
      text-align: center;
    }
  }
  .bContent-rotate-box {
    transform: rotateY(-180deg);
    position: absolute;
    left: 0;
    top: 0;
    .bContent {
      // background-color: #fff;
      padding: 28rem;
      // background-image: url('../assets/panel/b-content-bg.png');
      // background-repeat: no-repeat;
      // background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      #bindBackBg {
        z-index: -1;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        // object-fit: cover;
        // object-position: center;
      }
      .username {
        font-family: 'Noto Sans TC S';
        font-style: normal;
        font-weight: 900;
        font-size: 20rem;
        line-height: 30rem;
        /* identical to box height, or 150% */

        display: flex;
        align-items: center;
        letter-spacing: 1px;

        color: #5a0707;
      }
      .info-text {
        margin-bottom: 9rem;
        font-family: 'Noto Sans TC B';
        font-style: normal;
        font-weight: 600;
        font-size: 14rem;
        line-height: 24rem;
        /* identical to box height, or 171% */

        display: flex;
        align-items: center;
        letter-spacing: 1px;

        color: #000000;
      }
      .ai-box {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 237rem;
        height: 237rem;
        background-image: url('../assets/panel/b-content-ai-bg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .yellow-text {
          position: absolute;
          left: 23rem;
          top: 17rem;
          font-family: 'Noto Sans TC S';
          font-style: normal;
          font-weight: 700;
          font-size: 14rem;
          line-height: 20rem;
          /* identical to box height, or 143% */

          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: 1px;

          color: #e5b301;
        }
        #bindBackImg {
          margin: 47rem auto 11rem;
          // width: 88.81rem;
          width: auto;
          height: 128.98rem;
        }
        .ai-title {
          max-width: 100%;
          overflow: hidden;
          font-family: 'Noto Sans TC S';
          font-style: normal;
          font-weight: 500;
          font-size: 16rem;
          line-height: 26rem;
          /* identical to box height, or 162% */

          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: 1px;

          color: #000000;

          mix-blend-mode: normal;
        }
      }
      .ai-desc {
        overflow: hidden;
        height: 56rem;
        width: 80%;
        // width: 237rem;
        margin-top: 18rem;
        font-family: 'Noto Sans TC S';
        font-style: normal;
        font-weight: 500;
        font-size: 16rem;
        line-height: 28rem;
        // display: flex;
        // align-items: center;
        // text-align: center;
        letter-spacing: 1px;
        text-align: left;
        color: #000000;
      }
      .stt-box {
        display: flex;
        align-items: center;
        margin-top: 18rem;
        width: 95%;
        height: 80rem;
        .stt-icon {
          position: absolute;
          z-index: 4;
          left: 8rem;
          width: 68rem;
          height: 67.97rem;
        }
        .stt-text {
          width: 100%;
          overflow: hidden;
          position: relative;
          left: 8rem;
          padding: 20rem 20rem 20rem 38rem;
          font-family: 'Iansui 0.91';
          font-style: normal;
          font-weight: 400;
          font-size: 16rem;
          line-height: 24rem;
          height: 100%;
          background-image: url('../assets/panel/b-content-stt-bg.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
          display: flex;
          align-items: center;
          color: #000000;
          letter-spacing: 1rem;
        }
      }
      .type-desc {
        overflow: hidden;
        height: 60rem;
        width: 80%;
        margin-top: 18rem;
        font-family: 'Noto Sans TC S';
        font-style: normal;
        font-weight: 500;
        font-size: 14rem;
        line-height: 30rem;
        /* or 214% */
        // text-align: left;
        // display: flex;
        // align-items: center;
        text-align: center;
        letter-spacing: 1px;
        color: #000000;
      }
    }
  }
}
</style>
