<template>
  <div class="page-wrap">
    <div class="main">
      <audio
        style="display:none;"
        ref="audioElem"
        controls
        id="hide-audio"
        :src="epilogueAudio"
        @loadeddata="audioOnloadEv"
      ></audio>
      <img
        v-show="hasBgm && epilogueAudio"
        class="audio-icon"
        src="@/assets/music.png"
        alt=""
        @click.prevent.stop="pauseBgm"
      />
      <img
        v-show="!hasBgm && epilogueAudio"
        class="audio-icon"
        src="@/assets/no-music.png"
        alt=""
        @click.prevent.stop="playBgm"
      />
      <CustomDialog :msg="dialogMsg" :show.sync="showDialog" />
      <img src="@/assets/user/loading/loading-page.png" class="loading-page" v-if="isLoading" />
      <div class="panel-page" :class="{ hide: isLoading }">
        <p class="p1">你的專屬明信片</p>
        <div class="transform-box">
          <TurnAblePanel :showDialog.sync="showDialog" :panelData="panelData" ref="panel" />
        </div>
        <div class="transform-box-2">
          <img
            @click="handleTurn()"
            class="turn-page-text"
            src="@/assets/swiperPage/turn-page-text.png"
            alt=""
          />
          <div class="btn-box">
            <div class="btn1" @click="doDL">下載明信片</div>
            <div class="btn2" @click="goResultPage">完成</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EV } from '@/lib/kongkooV2-def';
import { wsAttrs } from '@/mixins/kongkoo-ws';
import remMixin from '@/mixins/useRem';
import TurnAblePanel from '@/components/TurnAblePanel.vue';
import { getPostcard } from '@/lib/kongkooV2-api';

export default {
  layout: 'empty',
  components: { TurnAblePanel },
  mixins: [remMixin, wsAttrs],
  data() {
    return {
      audioOnloadOk: false,
      hasBgm: false,
      showDialog: false,
      dialogMsg: '下載正在進行，請等待...',
      isLoading: true, // default is true
      isTurn: false, // false時明信片是正面，true的時候是反面
      convId: '',
      panelData: {},
      epilogueAudio: '',
    };
  },
  computed: {
    user() {
      return this.$store.state.kongkoo.user;
    },
    socket() {
      return this.$store.state.kongkoo.socket;
    },
    socketId() {
      return this.$store.state.kongkoo.socketId;
    },
    ques() {
      return this.$store.state.kongkoo.ques;
    },
    previewPage() {
      return !!this.$route.query.preview;
    },
    username() {
      if (this.previewPage) {
        return '王小明';
      }
      return this.$store.state.kongkoo.user.nickname || '';
    },
  },
  mounted() {
    // this.usePreviewDate();
    const { convId } = this.$route.query;
    this.convId = convId;
    try {
      if (this.previewPage) {
        this.usePreviewDate();
      } else {
        const promise = (async () => {
          const data = await this.getApiData();
          data.username = this.username;
          this.syncPanelData(data);
          this.panelData = data;
          if (data.epilogueAudio) {
            this.epilogueAudio = data.epilogueAudio;
          }
        })();
        this.waitTimeMin(promise, 3000).then(() => {
          this.isLoading = false;
        });
      }
    } catch (error) {
      console.log(error, 'panel.vue mounted error');
      this.isLoading = false;
    }
    setTimeout(() => {
      if (!this.isLoading) {
        this.isLoading = false;
      }
    }, 3000);
  },
  methods: {
    pauseBgm() {
      if (!this.hasBgm) return;
      this.$refs.audioElem.pause();
      this.hasBgm = false;
    },
    playBgm() {
      if (this.hasBgm) return;
      this.$refs.audioElem.play();
      this.hasBgm = true;
    },
    audioOnloadEv() {
      // this.audioOnloadOk = true;
      const { userAgent } = navigator;
      if (userAgent.indexOf('Firefox') > -1) {
        // 判断是否Firefox浏览器
        this.playBgm();
      }
    },
    goResultPage() {
      this.$router.push({
        path: '/kongkoo/user/result',
        query: {
          roomId: this.roomId,
        },
      });
    },
    async getApiData() {
      // eslint-disable-next-line no-return-await
      return await getPostcard(this.convId);
    },
    syncPanelData(data) {
      if (this.socket) {
        // eslint-disable-next-line no-param-reassign
        data.username = this.username;
        this.socket.emit(EV.user.set_state, { panelData: data });
      }
    },
    async usePreviewDate() {
      const data = {
        front: {
          avatar:
            // 'https://voiss-kongkoo.storage.googleapis.com/nh/imgs/%E6%B1%9F%E8%A8%98%E8%8F%AF%E9%9A%86/%E6%B1%9F%E8%A8%98%E8%8F%AF%E9%9A%86cover%E5%9C%96.png',
            'https://ws.voiss.cc/_static/mp_v2/stamp/%E8%8F%AF%E9%9A%86%E5%B8%83%E8%A1%8C%E7%B6%93%E6%AD%B7%E9%81%8E%E7%94%9F%E6%84%8F%E8%88%88%E9%9A%86%E7%9A%84%E8%B3%BA%E9%8C%A2%E6%99%82%E6%9C%9F.png',
          miniText: '江記華隆肉鬆生意開始經營之際',
          title: '江記華隆',
          subtitle: '《破碎的希望之一》',
          linkText: '了解更多店家資訊',
          date: '2022.3.10',
          innerBg:
            // 'https://voiss-kongkoo.storage.googleapis.com/nh/imgs/%E6%B1%9F%E8%A8%98%E8%8F%AF%E9%9A%86/%E6%B1%9F%E8%A8%98%E8%8F%AF%E9%9A%86cover%E5%9C%96.png',
            'https://ws.voiss.cc/_static/mp_v2/postcard/%E6%B1%9F%E8%A8%98%E8%8F%AF%E9%9A%86/bg.png',
          outerBg:
            // 'https://voiss-kongkoo.storage.googleapis.com/nh/imgs/%E6%B1%9F%E8%A8%98%E8%8F%AF%E9%9A%86/%E6%B1%9F%E8%A8%98%E8%8F%AF%E9%9A%86cover%E5%9C%96.png',
            'https://ws.voiss.cc/_static/mp_v2/postcard/%E6%B1%9F%E8%A8%98%E8%8F%AF%E9%9A%86/2.png',
        },
        back: {
          imgTitle: '無比睿智的人蔘',
          // img: 'https://voiss-kongkoo.storage.googleapis.com/nh/imgs/%E6%B1%9F%E8%A8%98%E8%8F%AF%E9%9A%86/%E6%B1%9F%E8%A8%98%E8%8F%AF%E9%9A%86cover%E5%9C%96.png', // 如果前端有的話這個欄位直接砍掉也可以
          img: 'https://ws.voiss.cc/_static/mp_v2/ai_role/%E6%8E%A2%E9%9A%AA%E5%AE%B6.png', // 如果前端有的話這個欄位直接砍掉也可以
          desc1: '別人眼中的你，具有 天資聰慧、機智可靠、心思縝密的人格特質',
          text: '可以創立救助會，讓沒有工作的人民有一個溫飽的地方。',
          desc2: '你就像個守護者，能做出這樣的決定，真的很有堅定又有愛心!',
          // bg: 'https://voiss-kongkoo.storage.googleapis.com/nh/imgs/%E6%B1%9F%E8%A8%98%E8%8F%AF%E9%9A%86/%E6%B1%9F%E8%A8%98%E8%8F%AF%E9%9A%86cover%E5%9C%96.png',
          bg: 'https://ws.voiss.cc/_static/mp_v2/postcard/%E6%B1%9F%E8%A8%98%E8%8F%AF%E9%9A%86/3.png',
        },
        username: '王小明',
      };
      this.panelData = data;
    },
    /**
     * 至少等待幾秒後才完成
     */
    waitTimeMin(taskPromise, time) {
      return new Promise((resolve, reject) => {
        const flag = [false, false];
        const p = taskPromise || Promise.resolve();
        // eslint-disable-next-line no-undef-init
        let results = undefined;
        p.then((taskRes) => {
          results = taskRes;
          flag[0] = true;
          if (flag[0] && flag[1]) {
            resolve(results);
          }
        });
        p.catch((e) => {
          reject(e);
        });
        setTimeout(() => {
          flag[1] = true;
          if (flag[0] && flag[1]) {
            resolve(results);
          }
        }, time);
      });
    },
    handleTurn() {
      this.$refs.panel.toggleTurn();
      this.isTurn = !this.isTurn;
    },
    doDL() {
      this.$refs.panel.doActionDownload();
    },
  },
};
</script>

<style lang="scss" scoped>
.audio-icon {
  z-index: 5;
  cursor: pointer;
  width: 40rem;
  position: absolute;
  right: 10rem;
  top: 10rem;
}
.hide {
  visibility: hidden;
}
.page-wrap {
  width: 100vw;
  height: 100vh;
  .main {
    position: relative;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 428rem;
    height: 100%;
    .loading-page {
      height: 100%;
      width: auto;
      display: block;
      margin: auto;
    }
    .panel-page {
      padding-top: 30rem;
      perspective: 800rem;
      width: 100%;
      height: 100%;
      background: radial-gradient(
        49.98% 49.97% at 49.99% 50%,
        #e8e3dc 0%,
        #e9d7c1 77.76%,
        #dec4a4 100%
      );
      .transform-box {
        transform: scale(0.87) translate(0, -20px);
      }
      .transform-box-2 {
        transform: translateY(-40rem);
      }
      .p1 {
        font-family: 'Noto Sans TC S';
        font-style: normal;
        font-weight: 700;
        font-size: 20rem;
        line-height: 23rem;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.2em;
        color: #ffffff;
      }
      .turn-page-text {
        display: block;
        margin: auto;
        cursor: pointer;
        height: 20rem;
        width: auto;
      }
      .btn-box {
        display: flex;
        justify-content: center;
        margin-top: 10rem;
        .btn1 {
          margin-right: 29rem;
          display: inline-block;
          cursor: pointer;
          padding: 5rem 15rem;
          font-size: 20rem;
          font-family: 'Noto Sans TC S';
          font-style: normal;
          font-weight: 700;
          color: #fff;
          background: #947056;
          box-shadow: inset -4rem -4rem 0rem rgba(0, 0, 0, 0.25);
          border-radius: 30rem;
        }
        .btn2 {
          display: inline-block;
          cursor: pointer;
          padding: 5rem 15rem;
          font-size: 20rem;
          font-family: 'Noto Sans TC S';
          font-style: normal;
          font-weight: 900;
          color: #6f5440;
          border: 2px solid #6f5440;
          border-radius: 30rem;
        }
      }
    }
  }
}
</style>
