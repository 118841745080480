<template>
  <svg class="page-wrap" v-bind="svgAttrs">
    <!-- :style="{ fontSize: textDS(storyTextTop) }" -->
    <text class="story-title" :x="textDX(storyTextTop)" y="500">
      {{ storyTextTop }}
    </text>
    <!-- :style="{ fontSize: textDS(storyTextBottom) }" -->
    <text class="story-title" :x="textDX(storyTextBottom)" y="600">
      {{ storyTextBottom }}
    </text>
  </svg>
</template>

<script>
import { A, ns } from '@/lib/kongkoo-def';

export default {
  mounted() {
    // window.onload = () => {
    //   this.$store.dispatch(ns('kongkoo', A.startGame));
    // };
  },
  data() {
    const w = 1920;
    const h = 1080;
    return {
      w,
      h,
    };
  },
  computed: {
    storyTextTop() {
      const { ques } = this.$store.state.kongkoo;
      return `${ques?.title || ''}`;
      // return '李臨秋載動的';
    },
    storyTextBottom() {
      const { ques } = this.$store.state.kongkoo;
      return `《${ques?.slogan || ''}》`;
      // return '面臨消失品牌的契機？';
      // return '詩詞世界';
    },
    svgAttrs() {
      const { w, h } = this;
      const obj = {
        width: w,
        height: h,
        viewBox: [0, 0, w, h].join(' '),
        // preserveAspectRatio: 'none',
        version: '1.1',
        xmlns: 'http://www.w3.org/2000/svg',
      };
      return obj;
    },
  },
  methods: {
    textDX(str = '') {
      const len = str.length;
      if (len <= 1) {
        return 930;
      }
      if (len === 2) {
        return 895;
      }
      if (len === 3) {
        return 860;
      }
      if (len === 4) {
        return 825;
      }
      if (len === 5) {
        return 790;
      }
      if (len === 6) {
        return 755;
      }
      if (len === 7) {
        return 740;
      }
      if (len === 8) {
        return 650;
      }
      if (len > 8) {
        return 620;
      }
      return 930;
    },
    // textDS(str = '') {
    //   // const len = str.length;
    //   return '60px';
    // },
  },
};
</script>

<style lang="scss" scoped>
.page-wrap {
  width: 100vw;
  height: 100vh;
  background-color: #dac4a8;
  background-image: url('../../assets/SvgLandingScreen/bg.svg');
  // background-image: url('../../assets/SvgLandingScreen/bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.story-title {
  font-family: 'Noto Sans TC B';
  font-style: normal;
  font-weight: 900;
  font-size: 60px;
  line-height: 86px;
  letter-spacing: 0.2em;
  fill: #fff;
  stroke: #fff;
}
</style>
