<template>
  <div style="padding: 20px">
    <template v-for="page in pages">
      <div :key="page">
        <router-link :to="`/kongkoo/user/${page}?preview=1`">
          {{ page }}
        </router-link>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pages: ['signIn', 'prepare', 'play', 'panel', 'result'],
    };
  },
};
</script>

<style></style>
