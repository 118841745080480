<template>
  <svg :width="width" :height="height" preserveAspectRatio="none">
    <g transform="translate(10,10)">
      <path :d="pathData" :fill="shadow" />
    </g>
    <path :d="pathData" :fill="color" />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#FFFFFF',
    },
    width: {
      type: Number,
      default: 640,
    },
    height: {
      type: Number,
      default: 720,
    },
    mode: {
      type: String,
      default: 'N',
    },
    r1: {
      type: Number,
      default: 0.7, // 度 (360 度制)
    },
    r2: {
      type: Number,
      default: 0.8, // 度 (360 度制)
    },
    shadow: {
      type: String,
      default: '#AAA8',
    },
    shadowShift: {
      type: Number,
      default: 10,
    },
  },
  computed: {
    pathData() {
      const {
        mode, r1, r2, width: w, height: h, shadowShift,
      } = this;
      const pi = Math.PI;

      // 左上的偏移量
      const d1 = w * Math.tan((r1 * pi) / 180);
      const d2 = w * Math.tan((r2 * pi) / 180);

      // 右下點的 x
      let p = [];
      const xRT = w - shadowShift;
      const yB = h - shadowShift;
      const xRB = xRT - d2;
      if (mode === 'N') {
        p = [`M0 ${d1}`, `L${xRT} 0`, `L${xRB} ${yB}`, `L${d2} ${yB}`, `L0 ${d1}`];
      } else if (mode === 'R') {
        p = ['M0 0', `L${xRT} ${d1}`, `L${xRB} ${yB}`, `L${d2} ${yB}`, `L0 ${d1}`];
      }
      return p.join(' ');
    },
  },
  data() {
    return {};
  },
};
</script>

<style></style>
